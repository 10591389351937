import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact"; 
import Loading from '../Loading';

class ZakatPerniagaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_assets: 0, //Total harta
            capital: 0, //Modal
            profit: 0, //Keuntungan
            receivables: 0, //Piutang
            debt: 0, //Hutang,
            slug:props.slug,
            type_nisab: 'emas',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            golds: [], 
        };
        this.handleCapitalChange = this.handleCapitalChange.bind(this);
        this.handleProfitChange = this.handleProfitChange.bind(this);
        this.handleReceivablesChange = this.handleReceivablesChange.bind(this);
        this.handleDebtChange = this.handleDebtChange.bind(this);
    }

    handleCapitalChange(evt) {
        this.setState({capital: Number(evt.target.value)});
    }

    handleProfitChange(evt) {
        this.setState({profit: Number(evt.target.value)});
    }

    handleReceivablesChange(evt) {
        this.setState({receivables: Number(evt.target.value)});
    }

    handleDebtChange(evt) {
        this.setState({debt: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);
 
        const dataGold = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);
        
        this.setState({services: data.data.data, golds: dataGold.data.data, isLoading: false}); 

   
    };
    render() {
        const total_assets = this.state.capital + this.state.profit + this.state.receivables - this.state.debt;
        const zakat = total_assets * 2.5 / 100;
        const {services} = this.state;
 
        if ((!this.state.golds[0] && !this.state.golds[1]) || (typeof this.state.golds[0] && this.state.golds[1])  === 'undefined') {
            return <Loading />
        }  
        
        const harga_emas = this.state.golds[0].value; 
        const nisab_emas = this.state.golds[1].value; 
        const totalNisab = harga_emas * nisab_emas;
        return (
            <Row>
                <Col>
                    <Form className="calculator-text">
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Perniagaan : {nisab_emas} gram emas, </span>
                                <span>harga emas mengikuti harga emas Antam  </span>
                                <span>adalah sebesar Rp. 
                                {harga_emas.toLocaleString(navigator.language, {minimumFractionDigits: 0})} per gram </span>
                                <span>sehingga nisab zakat perniagaan saat ini </span>
                                <span>adalah senilai : {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="capital">Aset atau Modal yang dimiliki</Label>
                            <Input id="capital"
                                   type="number"
                                   onChange={this.handleCapitalChange}
                                   placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="profit">Keuntungan selama 1 tahun</Label>
                            <Input
                                type="number"
                                onChange={this.handleProfitChange}
                                name="profit" id="profit" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="receivable">Piutang</Label>
                            <Input
                                type="number"
                                onChange={this.handleReceivablesChange}
                                name="receivable" id="receivable" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="hutang">Hutang yang harus dibayar(bulan berjalan)</Label>
                            <Input
                                type="number"
                                onChange={this.handleDebtChange}
                                name="hutang" id="hutang" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="total_assets">Nilai yang harus dibayar zakatnya</Label>
                            <Input type="text" name="total_assets" id="total_assets"
                                   value={total_assets.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                   disabled/>
                        </FormGroup>

                        {total_assets >= totalNisab ?
                            <Fragment>
                                <FormGroup>
                                    <Alert color="info">
                                        <span>JUMLAH ZAKAT PERNIAGAAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                                        <br/>
                                        <span>(2,5% dikali penghasilan kena zakat)</span>
                                        <br/>
                                        <span className="text-amount-zakat">Rp.{zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                                    </Alert>
                                </FormGroup>
                                <FormGroup>
                                    <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                                        <MDBBtn className="btn-infaq  btn-rounded" block>
                                            ZAKAT
                                        </MDBBtn>
                                    </Link>
                                </FormGroup>
                            </Fragment> :

                            <Fragment>
                                <FormGroup>
                                    <Alert color="info">
                                        <span>JUMLAH ZAKAT PERNIAGAAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                                        <br/>
                                        <span>(2,5% dikali penghasilan kena zakat)</span>
                                        <br/>
                                        <span className="text-amount-zakat">Rp.0 ,-</span>
                                    </Alert>
                                </FormGroup>
                                <FormGroup>
                                    <Link to="/programs">
                                        <MDBBtn className="btn-infaq btn-rounded" block>
                                            INFAQ
                                        </MDBBtn>
                                    </Link>
                                </FormGroup>
                            </Fragment>
                        }
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatPerniagaan;
