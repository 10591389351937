import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact"; 
import Loading from '../Loading';

class ZakatPerak extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_silver: 0, //Jumlah Perak Keseluruhan
            silver: 0, //Jumlah perak
            silver_used: 0, //Jumlah perak yang digunakan
            slug:props.slug,
            type_nisab: 'perak',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: '',
            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            }, 
            silvers: []
        };
        this.handleSilverChange = this.handleSilverChange.bind(this);
        this.handleSilverUsedChange = this.handleSilverUsedChange.bind(this);
    }

    handleSilverChange(evt) {
        this.setState({silver: Number(evt.target.value)});
    }

    handleSilverUsedChange(evt) {
        this.setState({silver_used: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        const dataSilver = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);

        this.setState({services: data.data.data, silvers: dataSilver.data.data, isLoading: false});
 
    };
    render() {
        
        const {services} = this.state;

        let validationZakat; 

        if ((!this.state.silvers[0] && !this.state.silvers[1]) || (typeof this.state.silvers[0] && this.state.silvers[1])  === 'undefined') {
            return <Loading />
        }
        
        const harga_perak = this.state.silvers[0].value; 
        const nisab_perak = this.state.silvers[1].value; 
        const totalNisab = harga_perak * nisab_perak;

        const total_silver = this.state.silver - this.state.silver_used;
        const total_assets = total_silver * harga_perak;
        const zakat = total_assets * 2.5 / 100; 

        if (total_silver >= nisab_perak) {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT PERAK YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.{zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT PERAK YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.0 ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>
        }
        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Perak : {nisab_perak} gram perak, </span>
                                <span>harga perak mengikuti harga perak Antam  </span>
                                <span>adalah sebesar Rp. {harga_perak.toLocaleString(navigator.language, {minimumFractionDigits: 0})} per gram </span>
                                <span>sehingga nisab zakat perak saat ini </span>
                                <span>adalah senilai : Rp.  {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah Perak (dalam gram)</Label>
                            <Input type="number" onChange={this.handleSilverChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah Perak yang digunakan (dalam gram)</Label>
                            <Input type="number" onChange={this.handleSilverUsedChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah perak wajib zakat</Label>
                            <Input type="text" name="pendapatan_utama" id="pendapatan_utama"
                                   value={total_silver.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                   readOnly/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatPerak;
