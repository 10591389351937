import React, {Component, Fragment} from 'react';
import axios from '../../utils/axios';
import {Link} from 'react-router-dom';
import {Col, Container, Media, Progress, Row} from 'reactstrap';
import ModalCategory from './tabs/components/ModalCategory';
import Loading from '../components/Loading'; 
import { MDBBtn } from "mdbreact";   
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown, faBoxes } from '@fortawesome/free-solid-svg-icons';   

class ListsFilter extends Component {
    constructor(props) {
        super(props);

        var uri = this.props.location.pathname;
        var result = uri.split("/");

        this.state = {
            query: '',
            programs: [],
            isLoading: false,
            visible: 5,
            error: false,
            slug: result[3]
        }; 
         
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 4};
        });
    }

    componentDidMount = async () => {
        try {
            this.setState({isLoading: true});

            const slug = this.state.slug;
            
            const data = await axios.get(`/programs/categories/${slug}`);

            if (data.data.length < 1) {
                const messages = {
                    ...this.state.messages,
                    show: true,
                    success: false
                };
                this.setState({messages});
            }

            this.setState({programs: data.data,  isLoading: false});
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const {data} = this.state.programs;
        const categoryTitle = this.props.match.params.slug; 
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        if (Object.keys(data).length === 0) {
            return (
                <Fragment>
                    <Container>
                        <Row>
                            <Col className="title" style={{textTransform: 'capitalize'}}>
                                {categoryTitle}
                            </Col>
                        </Row>
                        <Row>
                            <Media src={require('../../assets/images/lines.png')} style={{width: '130px', height: '20px'}}
                                className="lines-title"/>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faBoxes} size="4x" 
                                    style={{ color: '#e2e2e2', marginTop: '30px', marginLeft: '40%' }}/>  
                            </Col> 
                        </Row>
                        <Row>
                            <Col style={{ padding: '30px', textAlign: 'center', fontSize: '14px', color: '#84BD00'}}>
                                <span style={{ letterSpacing: '2px', fontWeight: 'bold'}}>
                                    Program tidak tersedia<br/>untuk kategori ini. 
                                </span>
                            </Col>
                        </Row> 
                    </Container>  
                    <ModalCategory/>
                </Fragment> 
            )
        }

        const programData = data.slice(0, this.state.visible).map((programs) => {
            return ( 
                <div className="pd-ls-lg">
                    <Col key={programs.slug} className="card-program-list" xs={{size: 12}}>
                        <Link to={`/programs/${programs.slug}`} style={{color: '#555'}}>
                            <Media
                                style={{background: '#fff' }}
                                className="image-program"
                                src={programs.images_feature}
                            />
                            <Col style={{padding: '0px'}}>
                                <Progress
                                    color="success"
                                    value={programs.precentage}
                                    className="progress-program"
                                >
                                    {programs.precentage}%
                                </Progress>
                            </Col>
                            <Row style={{padding: '5px', fontSize: '12px'}}>
                                <Col>
                                    Terkumpul
                                </Col>
                                <Col className="date-program">
                                    Sisa
                                </Col>
                            </Row>
                            <Row style={{padding: '0px 5px 5px 5px', fontSize: '11px'}}>
                                <Col>
                                    {programs.raised_format}
                                </Col>
                                <Col className="date-program">
                                    {programs.day_left} Hari
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="title-green-program">{programs.title}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="program-description">
                                    {programs.description}
                                </Col>
                            </Row>
                            <Link to={`/carts/${programs.id}`}>
                                <Row>
                                    <MDBBtn className='btn btn-color-primary btn-program btn-infaq btn-block'
                                            style={{color: '#fff'}} block>
                                            <span style={{color: '#fff'}}>
                                                INFAQ
                                            </span>
                                    </MDBBtn>
                                </Row>
                            </Link>
                        </Link>
                    </Col>
                </div>
            );
        });
        let loadMore;
 
        if (this.state.visible < data.length) { 
            loadMore = 
            <Fragment>
                <MDBBtn className="pulsate-bck" onClick={this.loadMore} color="primary" 
                        style={{ 
                            width: '50px',
                            borderRadius: '50%',
                            height: '50px',
                            fontSize: '20px',
                            background: '#fff',
                            color: '#9ecc49',
                            border: '1px solid #9ecc49',
                            marginBottom: '90px',
                            margin: 'auto',
                            display: 'block'}}>
                    <FontAwesomeIcon icon={faChevronDown}/>
                </MDBBtn>
            </Fragment>
        } 
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className="title" style={{textTransform: 'capitalize'}}>
                            {categoryTitle}
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} style={{width: '130px', height: '20px'}}
                               className="lines-title"/>
                    </Row> 
                    {programData} 
                    <Row>
                        <Col> 
                            <Row> 
                                <Col xs="12">
                                    {loadMore} 
                                </Col>
                            </Row>
                        </Col>
                    </Row> 
                    <Row>
                        <Col xs="8"/>
                        <Col>
                            <Media right src={require('../../assets/images/square-right.png')} className="square-right"
                                   style={{marginTop: '-75px'}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 6, offset: 3}} style={{paddingBottom: '20px'}}/>
                    </Row>
                </Container>
                <ModalCategory/>
            </Fragment>
        )
    }
}

export default ListsFilter;
