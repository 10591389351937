import React, {Component, Fragment} from 'react';
import {Col, Row} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import Loading from '../../components/Loading';
import { SocialIcon } from 'react-social-icons';

export default class TabDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            programs: props.programs,
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        }
    }

    render() {
        const data = this.props.programs;

        if (!data || typeof data === 'undefined') {
            return <Loading/>;
        }
        const content = data.content;

        const url = `https://zakatpedia.com${window.location.pathname}`;

        const whatsapp = `https://api.whatsapp.com/send?text=${data.title}%20via%20${url}%0A%0AALHAMDULILLAH%20MUDAH%20%21%0AKini%2C%20Zakat%2C%20Infaq%20dan%20Sedekah%20bisa%20ditunaikan%20di%20Zakatpedia..%20Dengan%20keunggulan%20Fitur%20Transaksi%20%3A%0A1.%20Transfer%20via%20%20Rekening%20Bank%20Mandiri,%20BCA,%20BNI%20Syariah,%20dan%20BSM%20%0A2.%20GO-PAY%20%0A3.%20Virtual%20Account%20%28Mandiri%20dan%20Permata%20Bank%29%20%0A4.%20Browse%20atau%20install%20Zakatpedia%20Apps%20di%20Google%20Play%0A%0ATunaikan%20Zakat%20anda%20klik%20%3A%20https%3A%2F%2Fzakatpedia.com%2Fservices%0A%0ATunaikan%20Sedekah%20anda%20klik%20%20%3A%20https%3A%2F%2Fzakatpedia.com%2Fservices%2Finfaq-sedekah%0A%0AInfaq%20Program%20Khusus%20klik%20%3A%20https%3A%2F%2Fzakatpedia.com%2Fprograms%0A%0ASemuanya%20Tunaikan%20di%20Zakatpedia%20saja.%21%21%0A%0A%23zakat%20%23zakatisizi%20%23zakatpedia%20%23zakatzamannow%20%23memudahkandimudahkan%20%23infaq%20%23sedekah%20%23zamannow%20%23kekinian%20%23milenial%20%23islam%20%23moslem%20%23moslemquotes`

        const twitter = `https://twitter.com/intent/tweet?text=${data.title}&via=InisiatifZakat&url=${url}&hashtags=izi,zakat`;

        return (
            <Fragment>
                <Col>
                    <Row style={{ paddingBottom: '16px' }}>
                        <Col xs="12">
                            { ReactHtmlParser(content) }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="share-program">
                            Bagikan
                        </Col>
                    </Row> <p/>
                    <Row>
                        <Col xs="12" style={{ textAlign: 'center' }}>
                            <SocialIcon network="whatsapp" url={whatsapp}/> &nbsp;
                            <SocialIcon network="twitter" url={twitter}/>
                        </Col>
                    </Row>
                </Col>
            </Fragment>
        )
    }
}
