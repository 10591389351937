import React, {Component} from 'react';
import Axios from '../../../utils/axios';
import Slider from 'react-slick';
import {Col, Media} from 'reactstrap';
import Loading from '../../components/Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            banners: [],
            isLoading: false,
            messages: {
                show: false,
                success: true,
                messages: 'No data to show'
            }
        }
    }

    componentDidMount = async () => {
        try {
            this.setState({isLoading: true});

            const {data} = await Axios.get(`banners`);

            if (data.data.length < 1) {
                const messages = {
                    ...this.state.messages,
                    show: true,
                    success: false
                }

                this.setState({messages});
            }

            this.setState({banners: data, isLoading: false});
        } catch (error) {
        }
    }

    render() {
        const {data} = this.state.banners;
        const sliders = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: false
        };
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const carousel = data.map((banners) => {
            return (
                <a key={banners.id} href={banners.link}>
                    <LazyLoadImage className="img-slider"  effect="blur" src={banners.image}  />  
                </a>

            );

        });

        return (
            <Col className="slider">
                <Slider {...sliders} arrows={false}>
                    {carousel}
                </Slider>
            </Col>
        )
    }
}

export default Carousel;
