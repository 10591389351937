import React, {Component, Fragment} from 'react';
import {Col, Media, Row} from 'reactstrap';
import axios from '../../../utils/axios';
import Loading from '../../components/Loading';
import { MDBBtn } from "mdbreact"; 
import numeral from 'numeral';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBell, faChevronDown } from '@fortawesome/free-solid-svg-icons'; 

export default class TabDonors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            programs: props.programs,
            donors: {
                id: '',
                name: '',
                email: '',
                avatar: '',
                is_anonymous: '',
                phone_validated: '',
                email_validated: '',
                deleted_at: '',
                created_at: '',
                updated_at: ''
            },
            visible: 5,
            error: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 4};
        });
    }

    componentDidFetchData = async () => {
        try {
            this.setState({isLoading: true}); 
            
            const slug = this.props.programs.slug; 
            const data = await axios.get(`/programs/${slug}/donor`);
            
            this.setState({donors: data.data, isLoading: false});
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    render() {
        const {data} = this.state.donors;  

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const donorData = data.slice(0, this.state.visible).map((donor) => {

            var checkingAnonymous;

            if(donor.is_anonymous) { 
                checkingAnonymous = <Fragment>
                    <Media left>
                        <Media src={ require('../../../assets/images/users.png')} style={{height: '65px', borderRadius: '100%'}}/>
                    </Media>
                    <Media body className="mr-4">
                        <Media heading style={{color: '#9ecc49', fontSize: '18px', paddingLeft: '10%'}} className="mt-0">
                            Hamba Allah
                        </Media>
                        <span style={{paddingLeft: '10%'}}>
                            Rp. {numeral(donor.amount).format('0,0')} 
                        </span>
                    </Media>
                </Fragment>; 
            } else { 
                checkingAnonymous = <Fragment>
                        <Media left>
                            <Media src={donor.avatar ? donor.avatar : require('../../../assets/images/users.png')} style={{height: '65px', borderRadius: '100%'}}/>
                        </Media>
                        <Media body className="mr-4">
                            <Media heading style={{color: '#9ecc49', fontSize: '18px', paddingLeft: '10%'}} className="mt-0">
                                {donor.is_anonymous ? 'Hamba Allah' : donor.name}
                            </Media>
                            <span style={{paddingLeft: '10%'}}>
                                Rp. {numeral(donor.amount).format('0,0')} 
                            </span>
                        </Media>
                    </Fragment>; 
            }

            return (
                <Media key={donor.id} className="mb-4">
                    {checkingAnonymous}                    
                </Media>
            )
        });

        let loadMore;

        if (this.state.visible < data.length) {
            loadMore =
                <Fragment>
                    <MDBBtn className="pulsate-bck" onClick={this.loadMore} color="primary" 
                            style={{ 
                                width: '50px',
                                borderRadius: '50%',
                                height: '50px',
                                fontSize: '20px',
                                background: '#fff',
                                color: '#9ecc49',
                                border: '1px solid #9ecc49',
                                marginBottom: '90px',
                                margin: 'auto',
                                display: 'block'}}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </MDBBtn>
                </Fragment>
        }

        if (Object.keys(data).length === 0) {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faBell} size="4x" 
                                style={{ color: '#e8edee', marginTop: '30px', marginLeft: '43%' }}/>  
                        </Col> 
                    </Row>
                    <Row>
                        <Col style={{ padding: '30px', textAlign: 'center', fontSize: '14px', color: '#84BD00'}}>
                            <span style={{ letterSpacing: '2px', fontWeight: 'bold'}}>
                               Belum ada Donatur <br/> untuk Program ini.
                            </span>
                        </Col>
                    </Row>  
                </Fragment> 
            )
        }
        return (
            <Fragment>  
                   {donorData}
                    <Row>
                        <Col xs="12">
                            {loadMore}
                        </Col>
                    </Row>  
            </Fragment>
        )
    }
}
