import React from 'react';
import App from './views/App';
import Home from './views/Home';
import ReactDOM from 'react-dom';
import './assets/css/zakatpedia.scss';
import queryString from 'query-string';
import Login from './views/auth/Login';
import {isLoggedIn} from './utils/auth';   
import Register from './views/auth/Register';
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './views/services/Lists';
import Programs from './views/programs/Lists';  
import ThankYou from './views/donation/ThankYou';
import Loading from './views/components/Loading';
import {isMobileOnly} from 'react-device-detect';  
import ProgramDetail from './views/programs/Detail';
import ServiceDetail from './views/services/Detail';
import TermService from './views/pages/TermService';
import ProgramCart from './views/donation/ProgramCart';
import CalculatorZakat from './views/pages/CalculatorZakat';
import Hashtag from './views/pages/Hashtag';
import ProfileDonation from './views/profiles/ListDonation';
import ProgramByCategory from './views/programs/ListsFilter';
import ProfileSetting from './views/profiles/ContactInformation';  

import './assets/scss/landscape/small/sm.scss';
import './assets/scss/landscape/small/md.scss';
import './assets/scss/landscape/small/lg.scss';
import './assets/scss/landscape/large/sm.scss';
import './assets/scss/landscape/large/md.scss';
import './assets/scss/landscape/large/lg.scss';
import './assets/scss/landscape/medium/sm.scss';
import './assets/scss/landscape/medium/md.scss';
import './assets/scss/landscape/medium/lg.scss'; 
import './assets/scss/landscape/xtra-large/sm.scss';
import './assets/scss/landscape/xtra-large/md.scss';

import './assets/scss/portrait/medium-x.scss';
import './assets/scss/portrait/small.scss';
import './assets/scss/portrait/large.scss';
import './assets/scss/portrait/medium.scss';
import './assets/scss/portrait/small-x.scss';
import './assets/scss/portrait/large-x.scss';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';   
import Iframe from './views/donation/components/Iframe';

const AuthRoute = props => {
    if (!isLoggedIn()) {
        return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
    }

    return <Route {...props}/>;
}; 

class AppRoot extends React.Component {
    constructor(props) {
        super(props);

        if (!isMobileOnly) {
            window.location = `https://zakatpedia.com${document.location.pathname}`;
        }
       
        const values = queryString.parse(window.location.search).referral;

        if(values) {

            sessionStorage.setItem('referral', values);
        }
    }

    render() {  

        if (!isMobileOnly) {
            window.location = `https://zakatpedia.com${document.location.pathname}`;

            return  <Loading />
        }

        return (
            <React.Fragment>
                <Router>
                    <React.Fragment>
                        <App/>
                        <Switch>
                            <Route exact path='/' component={Home}/>
                            <Route exact path='/login' component={Login}/>
                            <Route exact path='/register' component={Register}/>
                            <Route exact path='/services' component={Services}/>
                            <Route exact path='/programs' component={Programs}/>
                            <Route exact path='/kalkulator-zakat' component={CalculatorZakat}/>
                            <Route exact path='/term-service' component={TermService}/>
                            <Route exact path='/programs/categories/:slug' component={ProgramByCategory}/>
                            <Route exact path='/programs/:slug' component={ProgramDetail}/>
                            <Route exact path='/services/:id' component={ServiceDetail}/>

                            <Route exact path='/carts/success' component={ThankYou}/>
                            <Route exact path='/carts/:id' component={ProgramCart}/>
                            <Route exact path='/embed/kit' component={Iframe}/>
                            <AuthRoute exact path='/profiles/donation' component={ProfileDonation}/>
                            <AuthRoute exact path='/profiles/settings' component={ProfileSetting}/>

                            <Route exact path='/hashtag/:slug'  component={Hashtag}/>
                        </Switch>
                    </React.Fragment>
                </Router>
            </React.Fragment>
        );
    }
}

ReactDOM.render(<AppRoot/>, document.getElementById('root'));
