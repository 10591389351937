import React, { Component, Fragment } from 'react';
import { Col, Container, Media, Row } from 'reactstrap';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Calculator from './components/Calculator';
import Carousel from './dashboard/components/Carousel';
import ProgramPopular from './dashboard/components/ProgramPopular';
import ServicePopular from './dashboard/components/ServicePopular';
import PaymentMethod from './dashboard/components/PaymentMethod';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-awesome-modal';
import axios from '../utils/axios';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            visible: true,
            query: '',
            notification: {
                id: '',
                title: '',
                images_feature: '',
                description: '',
                type: '',
                slug: ''
            },
            isLoading: false,
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };
    }

    closeModal = () => {
        this.setState({
            modal: false
        });
    };

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await axios.get(`/notification`);

        const showModal = data.data.data ? true : false;

        this.setState({ notification: data.data.data, modal: showModal, isLoading: false });
    };

    render() {
        const { notification } = this.state;

        let typeNotification;
        let modalNotification;
        if (notification) {
            if (notification.type === 'PROGRAM') {
                typeNotification = <Link to={`/programs/${notification.slug}`}>
                    <button className='btn-modal-infaq' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                        Infaq
                        </button>
                </Link>;
            } else if (notification.type === 'SERVICE') {
                typeNotification = <Link to={`services/${notification.slug}`}>
                    <button className='btn-modal-infaq' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                        Zakat
                        </button>
                </Link>;
            } else {
                typeNotification = <a href={`${notification.target}`} target="_blank">
                    <button className='btn-modal-infaq' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                        Klik disini
                    </button>
                </a>;
            }

            modalNotification = <Modal
                visible={this.state.modal}
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}>
                <div className="modal-notification">
                    <Col>
                        <Row>
                            <Col xs={{ size: 2, offset: 10 }} onClick={() => this.closeModal()} style={{ paddingTop: '10px', paddingBottom: '5px' }}>
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ size: 12 }}>
                                {/* <LazyLoad height={200} offset={100} once>
                                <Media
                                    className='image-program-detail'
                                    src={notification.images_feature}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </LazyLoad> */}
                                <LazyLoadImage
                                    className="img-slider"
                                    effect="blur"
                                    src={notification.images_feature} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ size: 12 }} style={{ textAlign: 'center', color: '#8bc34a', paddingTop: '5px', fontWeight: 'bold', fontSize: '16px' }}>
                                <span> {notification.title} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ size: 12 }} style={{ textAlign: 'center', paddingTop: '5px' }}>
                                <span>{notification.description}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ size: 12 }} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                {typeNotification}
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Modal>;
        }


        return (
            <Fragment>
                <Container>
                    <Row>
                        <Carousel />
                    </Row>
                    <ServicePopular />
                    <Row>
                        <Col className='programs'>
                            <ProgramPopular />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="8" />
                        <Col style={{ marginBottom: '50px' }}>
                            <Media right src={require('../assets/images/square-right.png')} className="square-right" />
                        </Col>
                    </Row>
                    <Row className="row-calculator">
                        <Col className='calculator'>
                            <Row>
                                <Col>
                                    <Media src={require('../assets/images/calculator.png')} className='img-icon-calc' />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='title-calc'>
                                    Kalkulator Zakat
                                </Col>
                            </Row>
                            <Row>
                                <Media src={require('../assets/images/lines.png')} className='lines-title-calculator' />
                            </Row>
                            <Row>
                                <Col>
                                    <Calculator />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='payment-method'>
                            <PaymentMethod />
                        </Col>
                    </Row>

                    <Row>
                        <Col className='footer-dashboard'>
                            <Row>
                                <Col>
                                    <Media style={{ marginTop: '30px', width: '35%' }} src='https://zakatpedia.com/images/logo.png' />
                                </Col>
                            </Row>
                            <Row>
                                <a href="https://play.google.com/store/apps/details?id=id.izi.activity">
                                    <Media
                                        className="btn-playstore"
                                        src={require('../assets/images/playstore.png')}
                                    />
                                </a>
                                <span className="span-find-social">Temukan Kami di :</span>
                            </Row>
                            <Row>
                                <Col xs="5"> </Col>
                                <Col xs="2">
                                    <a href="https://www.facebook.com/InisiatifZakatID">
                                        <Media className="ic-social-footer" src={require('../assets/images/social/facebook.png')} />
                                    </a>
                                </Col>
                                <Col xs="2">
                                    <a href="https://www.instagram.com/inisiatifzakat">
                                        <Media className="ic-social-footer" src={require('../assets/images/social/instagram.png')} />
                                    </a>
                                </Col>
                                <Col xs="2">
                                    <a href="https://twitter.com/InisiatifZakat">
                                        <Media className="ic-social-footer" src={require('../assets/images/social/twitter.png')} />
                                    </a>
                                </Col>
                            </Row>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="footer-bottom">
                            <Row>
                                <Col>
                                    <span className="text-footer-small">
                                        Zakatpedia dikelola langsung oleh LAZNAS Inisiatif Zakat Indonesia.<br />Berdasarkan SK Mentri Agama RI No. 423 Tahun 2015
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {modalNotification}
            </Fragment>
        )
    }
}

export default Home;
