import React, {Component, Fragment} from 'react';
import {Col, Row} from 'reactstrap';
import axios from '../../../../utils/axios';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Modal from 'react-awesome-modal';
import {
    faBalanceScale,
    faBullhorn,
    faChevronCircleRight,
    faList,
    faStethoscope,
    faTimes,
    faUserGraduate,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/Loading';
import {MDBBtn} from "mdbreact";

class ModalCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            query: '',
            categories: [],
            isLoading: false,
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };
    }

    openModal = () => {
        this.setState({
            visible: true
        });
    };

    closeModal = () => {
        let location = window.location.pathname;

        let result = location.split("/");

        if (result.length > 2) {
            // eslint-disable-next-line
            location.href = location.href // Ini untuk apa ya? inisiatilasi ke diri sendiri?
        }

        this.setState({
            visible: false
        });
    };

    componentDidMount = async () => {
        try {
            this.setState({isLoading: true});

            const {data} = await axios.get(`/programs-category`);

            if (data.data.length < 1) {
                const messages = {
                    ...this.state.messages,
                    show: true,
                    success: false
                }
                this.setState({messages});
            }

            this.setState({categories: data, isLoading: false});
        } catch (error) {
        }
    };

    render() {
        const {data} = this.state.categories;
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }


        const categoryData = data.map((categories) => {
            let iconCategory;

            if (categories.slug === 'pendidikan') {
                iconCategory = <FontAwesomeIcon icon={faUserGraduate}/>
            } else if (categories.slug === 'kesehatan') {
                iconCategory = <FontAwesomeIcon icon={faStethoscope}/>
            } else if (categories.slug === 'dakwah') {
                iconCategory = <FontAwesomeIcon icon={faBullhorn}/>
            } else if (categories.slug === 'ekonomi') {
                iconCategory = <FontAwesomeIcon icon={faBalanceScale}/>
            } else if (categories.slug === 'sosial') {
                iconCategory = <FontAwesomeIcon icon={faUsers}/>
            } else {
                iconCategory = <FontAwesomeIcon icon={faChevronCircleRight}/>
            }

            return (
                <Fragment key={categories.id}>
                    <Link to={`/programs/categories/${categories.slug}`}
                          onClick={() => this.closeModal()}>
                        <Row className="row-category">
                            <Col style={{margin: '5px', color: '#555'}}>
                                <Row>
                                    <Col xs={{size: 2}} style={{color: '#84BD00'}}>
                                        {iconCategory}
                                    </Col>
                                    <Col>
                                        <span style={{fontWeight: 'bold'}}>
                                            {categories.name}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Link>
                </Fragment>
            );
        });

        return (
            <Fragment>
                <MDBBtn value="Open" onClick={() => this.openModal()}
                        className="button-fixed btn-category btn-flat btn btn-block" block>
                    <FontAwesomeIcon icon={faList}/> &nbsp;
                    Kategori
                </MDBBtn>
                <Modal
                    visible={this.state.visible}
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}>
                    <div className="modal-program-category">

                        <Col>
                            <Row>
                                <Col xs={{size: 10}}>
                                    <span>
                                        Kategori
                                    </span>
                                </Col>
                                <Col xs={{size: 2}} onClick={() => this.closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} size="lg"/>
                                </Col>
                            </Row>

                            <hr/>
                            {categoryData}
                        </Col>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

export default ModalCategory;
