import React, {Component, Fragment} from 'react';
import axios from '../../../utils/axios';
import {isLoggedIn} from '../../../utils/auth';
import LoadingCircle from '../../components/LoadingCircle';
import {Alert, Col, Form, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import Errors from '../../../assets/js/plugins/error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { MDBBtn } from "mdbreact";

export default class ContactInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoggedIn: false,
            errors: new Errors(),
            donor: {
                id: '',
                mobile: ''
            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);

    }

    componentDidFetchData = async () => {
        this.setState({isLoading: true});

        const request = await axios.get(`/profiles`);

        const data = request.data.data;
        const donors = data.donor;

        const donor = {
            ...this.state.donor,
            id: donors.id,
            mobile: donors.mobile
        };

        this.setState({donor, isLoading: false});
        this.setState({isLoggedIn: isLoggedIn()})
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    onInputDonorChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const donor = {...this.state.donor, [name]: value};

        const errors = new Errors(this.state.errors.getErrors());
        errors.clear(name);

        this.setState({errors, donor});
    };

    onHandleSubmit = async (event) => {
        console.log("kok")
        try {
            event.preventDefault();

            this.setState({isLoading: true});

            const data = await axios.put(`/profiles/update/contact`, {...this.state.donor});
            console.log(data);
            const messages = {...this.state.messages, show: true, success: data.success, message: data.message};

            this.setState({isLoading: false, messages});
        } catch (e) {
            this.setState({isLoading: false});
        }
    };

    onDismiss() {
        this.setState({visible: false});
    }

    render() {
        const {donor, messages} = this.state;

        let FormUpdate; 
         
        if (!this.state.isLoading)
        {
            FormUpdate =
            <Row className='row-info-profile'>
                <Col xs={{size: 10, offset: 1}}>
                    <Form onSubmit={this.onHandleSubmit}>
                        <FormGroup>
                            <Label for='mobile'>No. Handphone</Label>
                            <Input type='text' name='mobile'
                                    value={donor.mobile}
                                    onChange={this.onInputDonorChange}/>
                        </FormGroup>
                        <FormGroup>
                            <MDBBtn type='submit' className='btn-save-profile'>Simpan</MDBBtn>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
           
        } else {
            FormUpdate = <LoadingCircle />
        }

        return (
            <Fragment>
                <Row>
                    <Col className='title'>
                        Informasi Kontak
                    </Col>
                </Row>
                <Row>
                    <Media src={require('../../../assets/images/lines.png')} className='lines-title'/>
                </Row>
                {messages.show &&
                    <div>
                        <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                            <FontAwesomeIcon icon={faCheckCircle}/> &nbsp;
                            Berhasil disimpan !
                        </Alert>
                    </div>
                }
                {FormUpdate} 
            </Fragment>
        )
    }
}
