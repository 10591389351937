import React, {Component, Fragment} from 'react';
import axios from '../../../utils/axios';
import {isLoggedIn} from '../../../utils/auth';

import {Alert, Col, Form, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import Errors from '../../../assets/js/plugins/error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'; 
import LoadingCircle from '../../components/LoadingCircle';
import { MDBBtn } from "mdbreact";

export default class AccountInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoggedIn: false,
            errors: new Errors(),
            users: {
                id: '',
                name: '',
                email: '',
                avatar: ''
            },
            donor: {
                id: '',
                donor_number: '',
                mobile: '',
                address: '',
                rt: '',
                rw: '',
                sub_district: '',
                district: '',
                regency: '',
                province: '',
                postal_code: '',
                donor_type: '',
                marketer_id: '',
                branch_id: '',
                partner_id: '',
                intranet_donor_id: '',
                nik: '',
                npwp_number: '',
                npwp_name: '',
                npwp_address: '',
                npwp_office: ''
            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);

    }

    componentDidFetchData = async () => {
        this.setState({isLoading: true});

        const request = await axios.get(`/profiles`);

        const data = request.data.data;
        const donors = data.donor;

        const users = {
            ...this.state.users,
            id: data.id,
            email: data.email,
            name: data.name,
            avatar: data.avatar
        };

        const donor = {
            ...this.state.donor,
            id: donors.id,
            donor_number: donors.donor_number,
            mobile: donors.mobile,
            address: donors.address ? donors.address : '',
            rt: donors.rt,
            rw: donors.rw,
            sub_district: donors.sub_district,
            district: donors.district,
            regency: donors.regency ? donors.regency : '',
            province: donors.province ? donors.province : '',
            postal_code: donors.postal_code ? donors.postal_code : '',
            donor_type: donors.donor_type,
            marketer_id: donors.marketer_id,
            branch_id: donors.branch_id,
            partner_id: donors.partner_id,
            intranet_donor_id: donors.intranet_donor_id,
            nik: donors.nik,
            npwp_number: donors.npwp_number,
            npwp_name: donors.npwp_name,
            npwp_address: donors.npwp_address,
            npwp_office: donors.npwp_office
        };

        this.setState({users, donor, isLoading: false});

        this.setState({isLoggedIn: isLoggedIn()})
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const users = {...this.state.users, [name]: value};

        const errors = new Errors(this.state.errors.getErrors());
        errors.clear(name);

        this.setState({errors, users});
    };

    onInputDonorChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const donor = {...this.state.donor, [name]: value};

        const errors = new Errors(this.state.errors.getErrors());
        errors.clear(name);

        this.setState({errors, donor});
    };

    onHandleSubmit = async (event) => {
        try {
            event.preventDefault();

            this.setState({isLoading: true});

            const data = await axios.put(`/profiles/update`, {
                name: this.state.users.name,
                email: this.state.users.email,
                address: this.state.donor.address,
                regency: this.state.donor.regency,
                province: this.state.donor.province,
                postal_code: this.state.donor.postal_code
            });

            const messages = {...this.state.messages, show: true, success: data.success, message: data.message};

            this.setState({isLoading: false, messages});
        } catch (e) {
            this.setState({isLoading: false});
        }
    };

    onDismiss() {
        this.setState({visible: false});
    }

    render() {
        const {users, donor, messages, errors} = this.state;

        let FormUpdate; 
         
        if (!this.state.isLoading)
        {
            FormUpdate = 
                <Col xs={{size: 10, offset: 1}}>
                    <Form onSubmit={this.onHandleSubmit}>
                    
                        <FormGroup error={errors.has('name').toString()}>
                            <Label for='name'>Nama</Label>
                            <Input type='text' name='name' id='name' 
                                value={users.name} error={errors.has('name').toString()} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='exampleText'>Email</Label>
                            <Input type='text' name='email' id='email' value={users.email}
                                error={errors.has('email').toString()} readOnly/>
                        </FormGroup>
                        <FormGroup error={errors.has('address').toString()}>
                            <Label for='exampleText'>Alamat</Label>
                            <Input type='textarea' name='address' id='exampleText'
                                value={donor.address}
                                onChange={this.onInputDonorChange}
                                error={errors.has('address').toString()}/>
                        </FormGroup> 
                        <FormGroup>
                            <Label for='regency'>Kota</Label>
                            <Input type='text' name='regency' id='regency'
                                value={donor.regency}
                                onChange={this.onInputDonorChange}/>
                        </FormGroup>  
                        <FormGroup>
                            <Label for='province'>Provinsi</Label>
                            <Input type='text' name='province' id='province'
                                value={donor.province}
                                onChange={this.onInputDonorChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='postal_code'>Kode Pos</Label>
                            <Input type='text' name='postal_code' id='postal_code'
                                value={donor.postal_code}
                                onChange={this.onInputDonorChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <MDBBtn type='submit' className='btn-save-profile'> Simpan</MDBBtn>
                        </FormGroup>
                    </Form>
                </Col> 
        } else {
            FormUpdate = <LoadingCircle />
        }
        return (
            <Fragment>
                <Row>
                    <Col className='title'>
                        Informasi Akun
                    </Col>
                </Row>
                <Row>
                    <Media src={require('../../../assets/images/lines.png')} className='lines-title'/>
                </Row>
                {messages.show &&
                <div>
                    <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                        <FontAwesomeIcon icon={faCheckCircle}/> &nbsp;
                        Berhasil disimpan !
                    </Alert>
                </div>
                } 
                <Row className='row-info-profile'>
                    {FormUpdate} 
                </Row>
            </Fragment>
        )
    }
}
