import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";
import Loading from '../Loading';

class ZakatEmas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_gold: 0,
            gold: 0,
            gold_used: 0,
            slug:props.slug,
            type_nisab: 'emas',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            }, 
            golds: []
        };
        this.handleGoldChange = this.handleGoldChange.bind(this);
        this.handleGoldUsedChange = this.handleGoldUsedChange.bind(this);
    }

    handleGoldChange(evt) {
        this.setState({gold: Number(evt.target.value)});
    }

    handleGoldUsedChange(evt) {
        this.setState({gold_used: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);
       
        const dataGold = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);
        
        this.setState({services: data.data.data, golds: dataGold.data.data, isLoading: false}); 
    };

    render() {
        
        const {services} = this.state; 
        
        if ((!this.state.golds[0] && !this.state.golds[1]) || (typeof this.state.golds[0] && this.state.golds[1])  === 'undefined') {
            return <Loading />
        }  
        
        const harga_emas = this.state.golds[0].value; 
        const nisab_emas = this.state.golds[1].value; 
        const totalNisab = harga_emas * nisab_emas;

        const total_gold = this.state.gold - this.state.gold_used;
        const total_assets = total_gold * harga_emas;
        const zakat = total_assets * 2.5 / 100;

        let validationZakat;

        if (total_gold >= nisab_emas) {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT EMAS YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.{zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT EMAS YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">0</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        }

        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Emas : {nisab_emas} gram emas, </span>
                                <span>harga emas mengikuti harga emas Antam  </span>
                                <span>adalah sebesar Rp. {harga_emas.toLocaleString(navigator.language, {minimumFractionDigits: 0})} per gram </span>
                                <span>sehingga nisab zakat emas saat ini </span>
                                <span>adalah senilai : Rp. {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah Emas (dalam gram)</Label>
                            <Input type="number" onChange={this.handleGoldChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="pendapatan_lain">Jumlah Emas yang digunakan (dalam gram)</Label>
                            <Input type="number" onChange={this.handleGoldUsedChange} id="pendapatan_lain"
                                   placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="total_pendapatan">Emas wajib zakat</Label>
                            <Input type="text" name="total_pendapatan" id="total_pendapatan"
                                   value={total_gold.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                   disabled/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatEmas;
