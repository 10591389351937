import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";

export default class InfaqSodaqoh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infaq: 0,
            slug: props.slug,
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_categoy: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''
            }, messages: {
                show: false,
                success: true,
                message: 'Default mesage'
            }
        };
        this.handleAmountInfaqChange = this.handleAmountInfaqChange.bind(this);
    }
    handleAmountInfaqChange(event) {
        this.setState({
            infaq: Number(event.target.value)
        });
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        this.setState({services: data.data.data, isLoading: false});
    };

    render() {
        const infaq = this.state.infaq;
        const {services} = this.state;

        return(
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                        <blockquote>
                            <p className="quotation">
                                <span style={{color: '#9ecc49', fontWeight:'bold'}}>
                                    <i>
                                        (yaitu) orang-orang yang berinfak
                                    </i>
                                </span>, baik di waktu lapang maupun sempit,
                                dan orang-orang yang menahan amarahnya
                                dan memaafkan(kesalahan) orang lain.
                                Dan Allah mencintai orang yang berbuat kebaikan.
                            </p>
                            <footer-quote>
                                <span style={{ fontWeight: 'bold'}}>— (QS. Al - Imran : 134)</span>
                            </footer-quote>
                        </blockquote>

                        </FormGroup>
                        <FormGroup>
                            <Label for="infaq">Jumlah Infaq</Label>
                            <Input type="number" onChange={this.handleAmountInfaqChange } name="infaq"
                                   id="infaq" placeholder="Rp.0 ,"/>
                        </FormGroup>
                        <FormGroup>
                            <Alert color="info">
                                <span>JUMLAH INFAQ YANG DIBAYARKAN ADALAH:</span>
                                <br/>
                                <span>Rp. {infaq.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${infaq}`} }}>
                                <MDBBtn className="btn-infaq btn-rounded" block>
                                    INFAQ
                                </MDBBtn>
                            </Link>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        );
    }
}
