import React from 'react';
import numeral from 'numeral';

import {Card, CardBody} from 'reactstrap';

import Moment from 'react-moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import { MDBBtn } from "mdbreact";

function TransferTankYou(props) { 
    return (
        <Card>
            <CardBody>
                <p>
                    <span style={{ fontSize: '15px' }}>Mohon untuk melakukan pembayaran sebelum</span><br/><br/>
                    <Moment format={'dddd, DD MMMM YYYY'} add={{days: 3}} locale='id'> 
                        <span className="transaction_date">{props.donation.transaction_date}</span> 
                    </Moment> 
                    <br/><br/>
                    Dengan nominal : 
                </p>
                <CopyToClipboard text={props.donation.total_amount} onCopy={() => props.onCopy('amount')}>
                    <p>
                    <span className='font-weight-bolder' style={{fontSize: '28px'}}>
                    Rp. {numeral(props.donation.total_amount).format('0,0')}
                    </span><br/><br/>
                        {!props.copied.amount && 
                            
                            <MDBBtn className="copy">
                                <span>
                                    Copy &nbsp;<FontAwesomeIcon icon={faCopy}/> 
                                </span>
                            </MDBBtn>
                        }
                        {props.copied.amount && <span style={{color: '#9ecc49'}}>Nominal berhasil disalin!</span>}
                    </p>
                </CopyToClipboard> 
                    
                <br/>
                <p>Melalui rekening :</p>

                <CopyToClipboard text={props.donation.bank.account_number.replace(/[^\w\s]/gi, '')} onCopy={() => props.onCopy('account')}>
                    <p>
                        <img src={props.donation.bank ? props.donation.bank.image : '#'} alt='Logo Bank'/><br/>
                        <span style={{fontSize: '15px'}} className='font-weight-bolder'>
                            {props.donation.bank ? props.donation.bank.account_name : 'Yayasan Inisiatif Zakat Indonesia'}
                        </span><br/>
                        <span style={{fontSize: '15px'}} className='font-weight-bolder'>
                            {props.donation.bank ? props.donation.bank.account_number : '-'}
                        </span><br/><br/>
                        {!props.copied.account &&
                            <MDBBtn className="copy">
                                <span>
                                    Copy &nbsp;<FontAwesomeIcon icon={faCopy}/> 
                                </span>
                            </MDBBtn>
                        }
                        {props.copied.account && <span style={{color: '#9ecc49'}}>Nomor Rekening berhasil disalin !</span>}
                    </p>
                </CopyToClipboard>  
            </CardBody>
        </Card>
    );
}

export default TransferTankYou;
