import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Form, FormGroup, FormFeedback, FormText, Input, Label, Media, Row} from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';
import {isLoggedIn, setToken} from '../../utils/auth';
import axios from '../../utils/axios';
import FacebookAuth from './components/FacebookAuth';
import GoogleAuth from './components/GoogleAuth';
import LoadingCircle from '../components/LoadingCircle';
import Error from '../../utils/error'; 

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            name: '',
            email: '',
            address: '',
            password: '',
            error: new Error(),
            isLoggedIn: isLoggedIn(),
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name; 

        const error = new Error(this.state.error);
        error.clear(name);

        this.setState({ [name]: value, error });
    }

    onHandleSubmit = async (event) => {
        event.preventDefault();

        this.setState({isLoading: true});
        try {
            const {name, email, password} = this.state;

            const response = await axios.post('/auth/register',
                {name, email, password});

            setToken(response.data.token);

            this.setState({isLoggedIn: isLoggedIn(), isLoading: false})
        } catch ({ response }) {
            this.setState({ error: new Error(response.data.errors), isLoading: false});
        }
    };

    render() {
        const { error } = this.state;  
        if (this.state.isLoggedIn) {
            return <Redirect to="/"/>
        }
        let FormRegister; 
         
        if (!this.state.isLoading)
        {
            FormRegister = 
                <Col>
                    <Form onSubmit={this.onHandleSubmit} error={error.any()}> 
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='fullname' className='mr-sm-2'>Nama Lengkap</Label>
                            <Input type='text' name="name" onChange={this.onChange} id='fullname' />
                        </FormGroup>
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='exampleEmail' className='mr-sm-2'>Email</Label>
                            <Input type='email' name='email' onChange={this.onChange} id='exampleEmail' />
                        </FormGroup>
                        <FormGroup className='pd-ls'>
                            <FormFeedback tooltip>You will not be able to see this</FormFeedback>
                            <FormText error hidden={!error.any()}>
                                <span style={{ color: '#dc3545'}}>{error.get('email')}</span>
                            </FormText>
                        </FormGroup> 
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='password' className='mr-sm-2'>Password</Label>
                            <Input type='password' name='password' onChange={this.onChange} id='password'/>
                        </FormGroup>
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='password_confirmed' className='mr-sm-2'>Konfirmasi Password</Label>
                            <Input type='password' name='password_confirmed' onChange={this.onChange}
                                id='password_confirmed'/>
                        </FormGroup>
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='mobile' className='mr-sm-2'>Nomor Handphone</Label>
                            <Input type='number' name='mobile' onChange={this.onChange} id='mobile'/>
                        </FormGroup>
                        
                        <Row>
                            <Col>
                                <Row className="pd-ls">
                                    <Col>
                                        Sudah punya akun Zakatpedia?&nbsp;
                                        <Link to='/login'>
                                            <span className="text-green text-right">
                                                Masuk 
                                            </span>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='8'/>
                            <Col>
                                <Media right src='https://i.ibb.co/FqQcjg2/square-right.png'
                                    className='square-right'/>
                            </Col>
                        </Row>

                        <Row className="pd-ls">
                            <Col>
                                <Button className='btn-login' style={{fontWeight: '600'}} block>
                                    Daftar
                                </Button>
                            </Col>
                        </Row>
                        <Row className="pd-ls"> 
                            <h4 className='line-vertical'>
                                <span className='text-login-or'>
                                    Atau
                                </span>
                            </h4>
                        </Row>
                        <Row className="pd-ls">
                            <Col style={{marginBottom: '100px'}}>
                                <GoogleAuth/>
                                <FacebookAuth/>
                            </Col>
                        </Row>
                    </Form>
                </Col> 
        } else {
            FormRegister = <LoadingCircle />
        }
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className='title'>
                            Daftar akun Baru
                        </Col>
                    </Row>
                    <Row>
                        <Media src='https://i.ibb.co/7QpkyjV/lines.png' className='lines-title'/>
                    </Row>
                    <Row>
                        {FormRegister} 
                    </Row>
                </Container>
            </Fragment>
        )
    }

} 
