import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";
import Loading from '../Loading';

class ZakatPendapatan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_income: 0, //Penghasilan Utama
            income: 0, //Penghasilan Lain
            other_income: 0,
            slug:props.slug,
            type_nisab: 'beras',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            beras: []
        };
        this.handleIncomeChange = this.handleIncomeChange.bind(this);
        this.handleOtherIncomeChange = this.handleOtherIncomeChange.bind(this);
    }

    handleIncomeChange(evt) {
        this.setState({income: Number(evt.target.value)});
    }

    handleOtherIncomeChange(evt) {
        this.setState({other_income: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        const dataBeras = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);

        this.setState({services: data.data.data, beras: dataBeras.data.data, isLoading: false});
    };
    render() {
        const {services} = this.state;
        
        if ((!this.state.beras[0] && !this.state.beras[1]) || (typeof this.state.beras[0] && this.state.beras[1])  === 'undefined') {
            return <Loading />
        } 

        const harga_beras = this.state.beras[0].value; 
        const nisab_beras = this.state.beras[1].value; 
        const totalNisab = harga_beras * nisab_beras;

        const total_income = this.state.income + this.state.other_income;
        const zakat = total_income * 2.5 / 100;

        let validationZakat;

        if (total_income >= totalNisab) {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT PENDAPATAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali penghasilan kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">Rp. {zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT PENDAPATAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali penghasilan kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">Rp. 0 ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>
        }


        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Pendapatan : {nisab_beras} kg beras, </span>
                                <span>harga beras yang ditentukan dan disepakati </span>
                                <span>oleh IZI adalah senilai Rp. {harga_beras.toLocaleString(navigator.language, {minimumFractionDigits: 0})} per </span>
                                <span>kilogram sehingga nisab zakat pendapatan </span>
                                <span>saat ini adalah senilai : Rp. {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})} </span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Pendapatan Utama</Label>
                            <Input type="number" onChange={this.handleIncomeChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="Rp.0,-"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_lain">Bonus/THR/Pendapatan lainnya</Label>
                            <Input type="number" onChange={this.handleOtherIncomeChange} name="pendapatan_lain"
                                   id="pendapatan_lain" placeholder="Rp.0,-"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="total_pendapatan">Total Pendapatan</Label>
                            <Input type="text" name="total_pendapatan" id="total_pendapatan"
                                   value={total_income.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                   disabled/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatPendapatan;
