import React from 'react';
import {Card, CardBody} from 'reactstrap';
import Moment from "./VirtualAccountThankYou";
import numeral from "numeral";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {CopyToClipboard} from "react-copy-to-clipboard";

function DefaultThankYou(props) {
    const expired = (props) => (
        <Moment format={'dddd, DD MMMM YYYY'} add={{days: 3}} locale='id'>{props.donation.transaction_date}</Moment>
    );

    return (<>
        <Card>
            <CardBody>
                <p>Mohon untuk melakukan pembayaran sebelum {expired(props)} dengan nominal</p>

                <CopyToClipboard text={props.donation.total_amount} onCopy={() => props.onCopy('amount')}>
                    <p>
            <span className='font-weight-bolder' style={{fontSize: '28px'}}>
              Rp. {numeral(props.donation.total_amount).format('0,0')}
            </span><br/>
                        {!props.copied.amount && <span><FontAwesomeIcon icon={faCopy}/></span>}
                        {props.copied.amount && <span style={{color: '#9ecc49'}}>Copy to clipboard</span>}
                    </p>
                </CopyToClipboard>
            </CardBody>
        </Card>
    </>);
}

export default DefaultThankYou;
