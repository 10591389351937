import React, {Component, Fragment} from 'react';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import {Link} from 'react-router-dom';
import { MDBBtn } from "mdbreact"; 
import Loading from '../Loading';

class ZakatTabungan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_assets: 0, //Total Pendapatan
            savings: 0, // Jumlah Tabungan
            conventInterest: 0, //Jumlah Bunga Konvensional
            deposit: 0, //Jumlah Deposit Saham
            slug:props.slug,
            type_nisab:'emas',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            } , 
            golds: []
        };
        this.handleSavingsChange = this.handleSavingsChange.bind(this);
        this.handleConvenInterestChange = this.handleConvenInterestChange.bind(this);
        this.handleDepositChange = this.handleDepositChange.bind(this);
    }

    handleSavingsChange(evt) {
        this.setState({savings: Number(evt.target.value)});
    }

    handleConvenInterestChange(evt) {
        this.setState({conventInterest: Number(evt.target.value)});
    }

    handleDepositChange(evt) {
        this.setState({deposit: Number(evt.target.value)});
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        const dataGold = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);
        
        this.setState({services: data.data.data, golds: dataGold.data.data, isLoading: false}); 
    };

    render() {
        const total_assets = this.state.savings + this.state.conventInterest - this.state.deposit;
        const zakat = total_assets * 2.5 / 100;
        const {services} = this.state; 

        let validationZakat;
        
        if ((!this.state.golds[0] && !this.state.golds[1]) || (typeof this.state.golds[0] && this.state.golds[1])  === 'undefined') {
            return <Loading />
        }  
        
        const harga_emas = this.state.golds[0].value; 
        const nisab_emas = this.state.golds[1].value; 
        const totalNisab = harga_emas * nisab_emas;

        if (total_assets >= totalNisab) {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT TABUNGAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali penghasilan kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.{zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT TABUNGAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali penghasilan kena zakat)</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.0 ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <MDBBtn className="btn-infaq btn-rounded" block>
                            INFAQ
                        </MDBBtn>
                    </FormGroup>
                </Fragment>
        }

        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Tabungan : {nisab_emas} gram emas, </span>
                                <span>harga emas mengikuti harga emas Antam  </span>
                                <span>adalah sebesar Rp. 
                                {harga_emas.toLocaleString(navigator.language, {minimumFractionDigits: 0})}  
                                /per gram </span> 
                                <span>sehingga nisab zakat tabungan saat ini </span>
                                <span>adalah senilai : Rp. 
                                {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                </span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="pendapatan_utama">Uang tunai, Tabungan</Label>
                            <Input name="pendapatan_utama" id="pendapatan_utama" placeholder="Rp.0,-"
                                   type="number" onChange={this.handleSavingsChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="bunga_konvensional">Bunga(konvensional) dalam rupiah</Label>
                            <Input name="bunga_konvensional" id="bunga_konvensional"
                                   placeholder="Rp.0,-" type="number" onChange={this.handleDepositChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="deposito">Deposito, Saham atau surat - surat berharga lainnya</Label>
                            <Input name="deposito" id="deposito" placeholder="Rp.0,-"
                                   type="number" onChange={this.handleConvenInterestChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="total_pendapatan">Nilai yang harus dibayar zakatnya</Label>
                            <Input type="text" name="total_pendapatan" id="total_pendapatan" value={total_assets}
                                   disabled/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatTabungan;
