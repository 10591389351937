import React, {Component, Fragment} from 'react';
import axios from '../../utils/axios';
import {isLoggedIn} from '../../utils/auth';
import {Col, Container, Media, Row} from 'reactstrap';
import Errors from '../../assets/js/plugins/error';
import AccountInfo from './components/AccountInfo';
import ContactInfo from './components/ContactInfo';
import NpwpInfo from './components/NpwpInfo';
import PasswordInfo from './components/PasswordInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faBookReader, faUserCog } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

export default class ContactInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountInfo : false,
            contactInfo : false,
            npwpInfo : false,
            isLoading: false,
            isLoggedIn: false,
            errors: new Errors(),
            users: {
                id: '',
                avatar: '',
                name: '',
                created_at: ''
            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        };

        this.formAccount = this.formAccount.bind(this);
        this.formContact = this.formContact.bind(this);
        this.formNpwp = this.formNpwp.bind(this);

    }

    formAccount = async () => {
        this.setState({accountInfo: true, contactInfo: false, npwpInfo: false});
    };

    formContact = async () => {
        this.setState({contactInfo: true, accountInfo: false, npwpInfo: false});
    };

    formNpwp = async () => {
        this.setState({npwpInfo: true, accountInfo: false, contactInfo: false});
    };

    componentDidFetchData = async () => {
        this.setState({isLoading: true});

        const request = await axios.get(`/profiles`);

        const data = request.data.data;

        const users = {
            ...this.state.users,
            id: data.id,
            avatar: data.avatar,
            name: data.name,
            created_at: data.created_at
        };

        this.setState({users, isLoading: false});
        this.setState({isLoggedIn: isLoggedIn()})
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    render() {
        const {users} = this.state;
        console.log(users)

        let content;

        if(this.state.contactInfo) {
            content =
                <ContactInfo/>
        } else if (this.state.npwpInfo){
            content = <NpwpInfo/>
        }else {
            content =
                <Fragment>
                    <AccountInfo/>
                    <PasswordInfo/>
                </Fragment>
        } 
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col style={{ background: '#9ecc49' ,height: '190px'}}>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" style={{padding:'30px'}} >
                            <Row style={{ height: '200px',background: '#fff', marginTop: '-100px', borderRadius: '10px',
                                    boxShadow: '0 10px 6px -6px #c3c3c3',textAlign: 'center' }}>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Media src={users.avatar ? users.avatar : require('../../assets/images/users.png')}
                                                 className="avatar-user"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span style={{fontWeight: 'bold'}}>
                                                {users.name}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '30px'}}>
                                        <Col>
                                            <span style={{ fontSize: '12px'}}>
                                                Sejak&nbsp;
                                                <Moment format="DD/MM/YYYY">
                                                    {users.created_at}
                                                </Moment>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row style={{ color: '#9ecc49'}}>
                                        <Col className="profile-menu" onClick={this.formAccount} >
                                            <FontAwesomeIcon icon={faUserCog}/><br/>
                                            Akun
                                        </Col>
                                        <Col className="profile-menu" onClick={this.formContact} style={{ borderRight: '2px solid #eee', borderLeft: '2px solid #eee'}}>
                                                <FontAwesomeIcon icon={faMobileAlt}/><br/>
                                            Kontak
                                        </Col>
                                        <Col className="profile-menu" onClick={this.formNpwp}>
                                            <FontAwesomeIcon icon={faBookReader}/><br/>
                                            NPWP
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {content}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
