import React, {Component} from 'react';
import {Col, Media, Row} from 'reactstrap';
import axios from '../../../utils/axios';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTh} from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/Loading';

class ServicePopular extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            services: [],
            isLoading: false,
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };
    }

    componentDidMount = async () => {
        try {
            this.setState({isLoading: true});

            const {data} = await axios.get(`/services-popular`);

            if (data.data.length < 1) {
                const messages = {
                    ...this.state.messages,
                    show: true,
                    success: false
                }
                this.setState({messages});
            }

            this.setState({services: data, isLoading: false});

        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const {data} = this.state.services;

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }
        const serviceData = data.map((services) => {
            return (
                <Col key={services.slug} xs="3" className="service-square-4">
                    <Link to={`/services/${services.slug}`}>
                        <Row className="row-service-top">
                            <Col xs={{size: 6, offset: 1}}>
                                <Media className="img-layanan-4" src={services.images_icon}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 12}}>
                                <div className="service-title-3">{services.title}</div>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            );
        });
        return (  
            <Row className="service-popular-row">
                {serviceData}
                <Col xs="3" className="service-square-4">
                    <Row>
                        <Col className="card-image">
                            <FontAwesomeIcon icon={faTh} color="#9ecc49" size="lg" className="icon-dashboard-service"/>
                        </Col>
                    </Row>
                    <Col>
                        <Row>
                            <Link to="/services" style={{ margin: 'auto' }}>
                                <div className="service-title-others">Lainnya</div>
                            </Link>
                        </Row>
                    </Col>
                </Col>
            </Row>
        )
    }


}

export default ServicePopular;
