import React, {Component, Fragment} from 'react';
import axios from '../../../utils/axios';
import {isLoggedIn} from '../../../utils/auth';
import {Alert, Col, Form, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import Errors from '../../../assets/js/plugins/error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import LoadingCircle from '../../components/LoadingCircle';
import { MDBBtn } from "mdbreact";

export default class NpwpInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoggedIn: false,
            errors: new Errors(),
            donor: {
                nik: '',
                npwp_number: '',
                npwp_name: '',
                npwp_address: '',
                npwp_office: ''
            },
            messages: {
                show: false,
                success: true,
                message: 'Update Data NPWP Berhasil!'
            },
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidFetchData = async () => {
        this.setState({isLoading: true});

        const request = await axios.get(`/profiles`);

        const data = request.data.data;
        const donors = data.donor;

        const donor = {
            ...this.state.donor,
            id: donors.id,
            nik: donors.nik,
            npwp_number: donors.npwp_number,
            npwp_name: donors.npwp_name,
            npwp_address: donors.npwp_address,
            npwp_office: donors.npwp_office
        };

        this.setState({donor, isLoading: false});
        this.setState({isLoggedIn: isLoggedIn()})
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    onInputDonorChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const donor = {...this.state.donor, [name]: value};

        const errors = new Errors(this.state.errors.getErrors());
        errors.clear(name);

        this.setState({errors, donor});
    };

    onHandleSubmit = async (event) => {
        try {
            event.preventDefault();

            this.setState({isLoading: true});

            const data = await axios.put(`/profiles/update/npwp`, {...this.state.donor});

            const messages = {...this.state.messages, show: true, success: data.success, message: data.message};

            this.setState({isLoading: false, messages});
        } catch (e) {
            this.setState({isLoading: false});
        }
    };

    onDismiss() {
        this.setState({visible: false});
    }

    render() {
        const {donor, messages} = this.state;

        let FormUpdate; 
         
        if (!this.state.isLoading)
        {
            FormUpdate = 
            <Col xs={{size: 10, offset: 1}}>
                <Form onSubmit={this.onHandleSubmit}>
                    <FormGroup>
                        <Label for='npwp_name'>Nama</Label>
                        <Input type='text' name='npwp_name' id='npwp_name'
                            value={donor.npwp_name}
                            onChange={this.onInputDonorChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for='nik'>No. KTP</Label>
                        <Input type='text' name='nik' id='nik'
                            value={donor.nik}
                            onChange={this.onInputDonorChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for='npwp_number'>No. NPWP</Label>
                        <Input type='text' name='npwp_number' id='npwp_number'
                            value={donor.npwp_number}
                            onChange={this.onInputDonorChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for='npwp_address'>Alamat Kantor Pajak</Label>
                        <Input type='textarea' name='npwp_address' id='npwp_address'
                            value={donor.npwp_address}
                            onChange={this.onInputDonorChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for='npwp_office'>Kantor Pajak</Label>
                        <Input type='text' name='npwp_office' id='npwp_office'
                            value={donor.npwp_office}
                            onChange={this.onInputDonorChange}/>
                    </FormGroup>
                    <FormGroup>
                        <MDBBtn type='submit' className='btn-save-profile'> Simpan</MDBBtn>
                    </FormGroup>
                </Form>
            </Col> 
        } else {
            FormUpdate = <LoadingCircle />
        }

        return (
            <Fragment>
                <Row>
                    <Col className='title'>
                        Informasi NPWP
                    </Col>
                </Row>
                <Row>
                    <Media src={require('../../../assets/images/lines.png')} className='lines-title'/>
                </Row>
                {messages.show &&
                <div>
                    <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                        <FontAwesomeIcon icon={faCheckCircle}/> &nbsp;
                        Berhasil disimpan !
                    </Alert>
                </div>
                }
                <Row className='row-info-profile'> 
                    {FormUpdate} 
                </Row>
            </Fragment>
        )
    }
}
