import React, {Component} from 'react';
import {isLoggedIn, session} from '../utils/auth'

import HeaderDashboard from '../views/auth/components/Header';

class App extends Component {
    state = {
        isLoggedIn: false
    };

    componentDidMount() {
        this.setState({isLoggedIn: isLoggedIn()})
    }

    render() {
        const user = session();

        return <HeaderDashboard name={user.name} email={user.email} avatar={user.avatar} donor={user.donor}/>;
    }
}

export default App;
