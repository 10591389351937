import React, {Component, Fragment} from 'react';

import {Redirect, withRouter, Link} from 'react-router-dom';
import numeral from 'numeral';
import {Badge, Col, Container, InputGroup,InputGroupAddon, Form, FormGroup, Input, Media, Progress, Row} from 'reactstrap';
import lines from '../../assets/images/lines.png';
import axios from '../../utils/axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import CartSummary from './CartSummary';
import { MDBBtn } from "mdbreact";
import LoadingCircle from '../components/LoadingCircle'; 
import { isLoggedIn } from '../../utils/auth'; 

const style = {
    title: {
        background: 'rgb(255, 255, 255)',
        borderTop: '2px solid rgb(255, 255, 255)',
        borderBottom: '2px solid rgb(255, 255, 255)',
        color: 'rgb(85, 85, 85)',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    buttonSave: {
        fontWeight: 'bold',
        background: '#84BD00',
        border: 'none',
        fontSize: '15px',
        padding: '12px'
    },
    buttonAdd: {
        fontWeight: 'bold',
        fontSize: '14px',
        background: '#3697f3',
        border: 'none'
    }
};

class ProgramCart extends Component {
    state = {
        isLoading                : false,
        program                  : {},
        banks                    : [],
        program_amount           : [],
        donation                 : {
            id                   : '',
            method               : '',
            bank_id              : '',
            amount               : 0,
            code_unique          : 0,
            service_fee          : 0,
            virtual_account_code : '',
            referral             : '',
            name_donor           : '',
            email_donor          : '',
            phone_donor          : '',
            is_primary           : 1,
            transaction_number   : '',
            transaction_type     : '',
            donor_type           : 'PERSONAL'

        }, 
        amount_selected          : '',
        va_number                : '',
        redirect                 : false,
        epayment                 : false,
        error_name               : '',
        error_phone              : '',
        error_email              : '',
        error_transaction        : '',
        error_bank               : ''
    };

    componentDidMount = async () => {
        await this.onFetchProgram();
        await this.onFetchBank();
        await this.onFetchProgramAmount();
        if(this.props.location.state)
        {
            if(this.props.location.state.amount) {
                await this.onFetchAmount();
            }
        }
        if(sessionStorage.getItem('referral') !== 'undefined') { 
            await this.onFetchReferal();
        }
    };

    onFetchProgram = async () => {
        const id = this.props.match.params.id;
        const {data} = await axios.get(`/programs/${id}`);

        this.setState({program: data.data });
    };

    onFetchBank = async () => {
        const {data} = await axios.get(`/banks`);

        this.setState({banks: data.data});
    };

    onFetchProgramAmount = async () => {
        const {data} = await axios.get(`/program/amounts`);

        this.setState({program_amount: data.data});
    }
    onFetchAmount = async () => {
        const donation = {
            ...this.state.donation,
            amount:  this.props.location.state.amount
        };

        this.setState({ donation });
    };

    onFetchReferal = async () => {
        const donation = {
            ...this.state.donation,
                referral: sessionStorage.getItem('referral')
        };
        this.setState({ donation });
    }

    onCalculateServiceFee = (event, donation) => {
        let service_fee = 0;
        switch (event.target.value) {
            case 'permata' :
            case 'echannel' :
                service_fee = 4000;
                return service_fee;
            default:
                service_fee = 0.02 * (parseInt(donation.amount) || 0);
                return service_fee;
        }
    };

    onHandleChangePaymentMethod = event => {
        return event.target.value === 'transfer' ? Math.floor(Math.random() * (999 - 100) + 100) : 0;
    };

    onHandleChange = event => {
        const donation = {...this.state.donation, [event.target.name]: event.target.value};

        if (event.target.name === 'method') {
            donation.code_unique = this.onHandleChangePaymentMethod(event);
        }

        if (event.target.name === 'method' && event.target.value === 'gojek') {
            donation.service_fee = this.onCalculateServiceFee(event, donation);
            donation.virtual_account_code = event.target.value;
        }

        if (event.target.name === 'virtual_account_code') {
            donation.service_fee = this.onCalculateServiceFee(event, donation);
        }

        this.setState({donation});
    };

    onHandleVirtualAccount = async () => {
        try {
            const {method, amount, code_unique, virtual_account_code, referral} = this.state.donation;

            const {data: response} = await axios.post('/donations/midtrans', {
                transaction_type: method.toUpperCase(),
                virtual_account_code: virtual_account_code.toUpperCase(),
                amount,referral,
                code_unique,
                details: [
                    {program_id: this.props.match.params.id, amount}
                ]
            });

            const {data: donation} = response;

            /* eslint-disable */
            snap.pay(donation.snap_token, {
                onPending: result => {
                    if(result.bill_key || result.permata_va_number) {
                        const {bill_key, biller_code, permata_va_number} = result;

                        const va_number = permata_va_number ? permata_va_number : biller_code.toString() + bill_key.toString();

                        this.setState({donation: {...this.state.donation, id: donation.id}, va_number, redirect: true});
                    }

                    this.setState({donation: {...this.state.donation, id: donation.id}, redirect: true});
                },
                onError: result => {
                    console.error(result);
                },
                skipOrderSummary: true
            });
        } catch (e) {
            console.error(e)
        }
    };
    onHandleTransfer = async () => {
        try {
            const {method, bank_id, amount, code_unique, referral
                    ,name_donor, email_donor, 
                    phone_donor, is_primary, donor_type } = this.state.donation;

            const {data: response} = await axios.post('/donations/transfer',  isLoggedIn() ?  {
                    transaction_type: method.toUpperCase(),
                    bank_id, amount, code_unique, referral, 
                    details: [ {program_id: this.props.match.params.id, amount} ]
                } : {
                    transaction_type: method.toUpperCase(),
                    bank_id, amount, code_unique, referral,
                    name_donor, email_donor, 
                    phone_donor, is_primary, donor_type,

                    details: [
                        {program_id: this.props.match.params.id, amount}
                    ]
                });

            const {data: donation} = response;

            this.setState({donation: {...this.state.donation, id: donation.id}, redirect: true});
        } catch (error) { 
            const object = error.response.data.errors; 
            this.setState({
                error_name        : object.name_donor ? 'Nama wajib diisi!' : '', 
                error_phone       : object.phone_donor ? 'No.Telepon wajib diisi!' : '', 
                error_email       : object.email_donor ? 'Email wajib diisi!' : '',
                error_transaction : object.transaction_type ? 'Pilih Jenis Transaksi!' : '', 
                error_bank        : object.bank_id ? 'Pilih No. Rekening!' : ''}); 
        };
    };

    onHandleEpayment= async () => {
        try {
            const {method, amount, code_unique, referral
                    ,name_donor, email_donor, transaction_number,
                    phone_donor, is_primary, donor_type } = this.state.donation;

            const {data: response} = await axios.post('/donations/espay',  isLoggedIn() ?  {
                    transaction_type: method.toUpperCase(), amount, code_unique, referral, transaction_number,
                    details: [ {program_id: this.props.match.params.id, amount} ]
                } : {
                    transaction_type: method.toUpperCase(),
                    amount, code_unique, referral,
                    name_donor, email_donor, 
                    phone_donor, is_primary, donor_type, transaction_number,

                    details: [
                        {program_id: this.props.match.params.id, amount}
                    ]
                });

            const {data: donation} = response; 
            
            this.setState({donation: {...this.state.donation, id: donation.id, transaction_number: donation.transaction_number, transaction_type: donation.transaction_type}, epayment: true});
        } catch (error) { 
            const object = error.response.data.errors; 
            this.setState({
                error_name        : object.name_donor ? 'Nama wajib diisi!' : '', 
                error_phone       : object.phone_donor ? 'No.Telepon wajib diisi!' : '', 
                error_email       : object.email_donor ? 'Email wajib diisi!' : '',
                error_transaction : object.transaction_type ? 'Pilih Jenis Transaksi!' : '', 
                error_bank        : object.bank_id ? 'Pilih No. Rekening!' : ''}); 
        };
    };

    onHandleSubmit = async (event) => { 
        event.preventDefault();

        this.setState({isLoading: true});

        const donation = {...this.state.donation};

        switch (donation.method) {
            case 'va':
            case 'gojek':
                await this.onHandleVirtualAccount();
                break;
            case 'ovo':
            case 'jenius':
                await this.onHandleEpayment();
                break;
            default:
                await this.onHandleTransfer();
        }

        this.setState({isLoading: false});
    };
     
      onProgramAmountChanged = async (event) => {
        const donation = {
            ...this.state.donation,
            amount:  event.currentTarget.value
        };

        this.setState({ donation });
    };
    render() { 
        if (this.state.redirect && this.state.donation.id !== '') { 
            return <Redirect
                to={`/carts/success?donation=${this.state.donation.id}&va_number=${this.state.va_number}`}/>
        }  
        
        if (this.state.epayment && this.state.donation.id !== '') { 
            return <Redirect to={`/embed/kit?donation=${this.state.donation.id}`} />
        }
         
        let checkingAmount;

        let ImageDonation;

            if(this.props.location.state) {
                checkingAmount = <FormGroup>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend" style={{ fontWeight: 'bold'}}>
                            Rp.
                        </InputGroupAddon>
                        <Input
                            value={numeral(this.state.donation.amount || 0).format('0,0')} onChange={this.onHandleChange}
                        />
                    </InputGroup>
                    <Input type='hidden' name='amount' value={this.state.donation.amount || 0} readOnly/>
                </FormGroup>
            } else {
                
                checkingAmount = 
                    <Fragment>
                        <ul className="ks-cboxtags">
                            {this.state.program_amount.length > 0 && this.state.program_amount.map(amounts => ( 
                                <li key={amounts.id} style={{  margin: '5px' }}>
                                    <input type="radio" className="selected-round" id={`checkbox-${amounts.id}`} name="select"  
                                        checked={this.state.amount_selected === amounts.amount}
                                        onChange={this.onProgramAmountChanged} value={amounts.amount}/>
                                        <label className="round-label" for={`checkbox-${amounts.id}`}>
                                            <span>{numeral(amounts.amount || 0).format('0,0')}</span>
                                        </label> 
                                </li>)) }
                        </ul> 
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend" style={{ fontWeight: 'bold'}}>
                                    Rp.
                                </InputGroupAddon>
                                <Input
                                    type='number'
                                    name='amount'
                                    // value={this.state.amount_selected}
                                    value={this.state.donation.amount }
                                    placeholder='0 ,-' 
                                    onChange={this.onHandleChange} 
                                />
                            </InputGroup>
                        </FormGroup>    
                    </Fragment>
                
            }

        if(this.state.program.images_feature) {
            ImageDonation =  <Row>
                        <Media className="image-cart"  src={this.state.program.images_feature}/>
                    </Row>
        }

        let formDonor;
        if(!isLoggedIn()) {
            formDonor = <Fragment>
                 <FormGroup> 
                                    <Link to='/login' >
                                        <span style={{color: '#84bd01'}}>Masuk</span>
                                    </Link> 
                                    <span style={{fontSize: '14px', textAlign: 'center'}}> atau lengkapi data dibawah ini:</span>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup> 
                                        <Input placeholder="Nama Lengkap" name='name_donor'
                                            value={this.state.donation.name_donor }
                                            onChange={this.onHandleChange} />
                                    </InputGroup>
                                    <span style={{color: '#c0392b', fontSize: '12px', fontWeight: 'bold'}}>{this.state.error_name}</span>
                                </FormGroup>   
                                <FormGroup>
                                    <InputGroup> 
                                        <Input placeholder="Email" name='email_donor'
                                            value={this.state.donation.email_donor }
                                            onChange={this.onHandleChange} />
                                    </InputGroup>
                                    <span style={{color: '#c0392b', fontSize: '12px', fontWeight: 'bold'}}>{this.state.error_email}</span>
                                </FormGroup>   
                                <FormGroup>
                                    <InputGroup> 
                                        <Input placeholder="No.Telepon" name='phone_donor'
                                            value={this.state.donation.phone_donor }
                                            onChange={this.onHandleChange} />
                                    </InputGroup>
                                    <span style={{color: '#c0392b', fontSize: '12px', fontWeight: 'bold'}}>{this.state.error_phone}</span>
                                </FormGroup>  
                                <Input name='donor_type' type='hidden' value='PERSONAL'
                                    value={this.state.donation.donor_type}
                                />
                                <Input name='is_primary' type='hidden' value='1'
                                    value={this.state.donation.is_primary}
                                />

            </Fragment>
        }


        let formDonation;

        if( !this.state.isLoading) {
            formDonation = <Fragment>
                {ImageDonation}
                    <Row className="pd-ls">
                        <Col className='ml-3 mr-3' style={style.title}>
                            {this.state.program.title}
                        </Col>
                    </Row>
                <Row className='mb-2 pd-ls'>
                        <Col xs='11' className='card-donation rounded-0'>
                            <Row className='pt-2'>
                                <Col col='6'>
                                    <span style={{fontSize: '14px'}}>
                                        Rp. {this.state.program.goal_format}
                                    </span>
                                </Col>
                                <Col col='6'>
                                    <Row>
                                        <Col>
                                            <span style={{fontSize: '14px'}} className='float-right'>
                                                <Badge color='secondary' className='mt-1'>
                                                    {this.state.program.precentage} %
                                                </Badge>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Progress color='success' value={this.state.program.precentage}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col col='6'>
                                    <span style={{fontSize: '14px', color: '#9ecc49'}}>
                                        Rp. {this.state.program.raised_format}
                                    </span><br/>
                                    <span style={{fontSize: '14px'}}>
                                        Terkumpul
                                    </span>
                                </Col>
                                <Col col={{size: 4, offset: 2}}>
                                    <span style={{fontSize: '14px'}} className='float-right'>
                                        {this.state.program.day_left}
                                    </span><br/>
                                    <span style={{fontSize: '14px'}} className='float-right'>
                                        Hari lagi
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pt-3 pd-ls'>
                        <Col className='ml-1 mr-1'>
                            <Form onSubmit={this.onHandleSubmit}>    
                                {checkingAmount}
                                <FormGroup >
                                    <Input type='select' name='method' onChange={this.onHandleChange}
                                           value={this.state.donation.method} className="selectMethod" >
                                        <option value='' >Metode Pembayaran</option>
                                        <option value='gojek'>Via GO-PAY</option>
                                        <option value='va'>Via Transfer Virtual Account</option>
                                        <option value='transfer'>Via Bank Transfer</option>
                                        <option value='ovo'>Via OVO</option>
                                        <option value='jenius'>Via JENIUS</option>
                                    </Input>
                                    <span style={{color: '#c0392b', fontSize: '12px', fontWeight: 'bold'}}>{this.state.error_transaction}</span>
                                </FormGroup>
                                {(this.state.donation.method === 'transfer' && this.state.banks.length > 0) &&
                                <FormGroup>
                                    <Input type='select' name='bank_id' onChange={this.onHandleChange}
                                           value={this.state.donation.bank_id} >
                                        <option value=''>Pilih Bank Rekening</option>
                                        {this.state.banks.length > 0 && this.state.banks.map(bank => (
                                            <option key={bank.id}
                                                    value={bank.id}>{`${bank.name} - ${bank.account_number}`}</option>
                                        ))}
                                    </Input>
                                    <span style={{color: '#c0392b', fontSize: '12px', fontWeight: 'bold'}}>{this.state.error_bank}</span>
                                </FormGroup>}
                                {(this.state.donation.method === 'va') &&
                                <FormGroup>
                                    <Input type='select' name='virtual_account_code' onChange={this.onHandleChange}
                                           value={this.state.donation.virtual_account_code} >
                                        <option value=''>Pilih Virtual Account</option>
                                        <option value='permata'>Virtual Account Bank Permata</option>
                                        <option value='echannel'>Virtual Account Bank Mandiri</option>
                                    </Input>
                                </FormGroup>}
                                <FormGroup>
                                    <CartSummary donation={this.state.donation}/>
                                </FormGroup>
                                {formDonor}
                                <FormGroup>
                                    <MDBBtn
                                        type='submit'
                                        style={style.buttonSave}
                                        block
                                        disabled={!this.state.program.id}>
                                        <span style={{color: '#fff'}}>
                                            Bayar Sekarang &nbsp;
                                            <FontAwesomeIcon icon={faHandHoldingHeart}/>
                                        </span>
                                    </MDBBtn>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
            </Fragment>;
        } else {
            formDonation = <LoadingCircle />;
        }
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col style={{fontWeight: 'bold'}}>Donasi</Col>
                    </Row>
                    <Row>
                        <Media src={lines} className='lines-title'/>
                    </Row> 
                    {formDonation}  
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(ProgramCart);

