import React, {Component, Fragment} from 'react'; 
import Loading from '../components/Loading';
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router-dom';
import axios from '../../utils/axios';
import {Col, Container, Media, Row, Progress} from 'reactstrap';
import {MDBBtn} from 'mdbreact'; 

class Hashtag extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            hashtag: {
                title           : '',
                slug            : '', 
                description     : '',
                images_feature  : '',
                images_banner   : '' 
            },
            programs: [],
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            }, 
            visible: 1
        };

        this.loadMore = this.loadMore.bind(this);
    } 

    loadMore() {
        this.setState((prev) => {
            return {visible: prev.visible + 4};
        });
    }

    componentDidMount = async () => {

        try {
            this.setState({isLoading: true});
 
            const slug = this.props.match.params.slug;

            const hashtag = await axios.get(`/hashtag/${slug}`);

            const program = await axios.get(`/hashtag/program/${slug}`); 
            this.setState({hashtag: hashtag.data.data, programs: program.data.data, isLoading: false});
        } catch (error) {
            console.log(error);
        } 
        
        
    };
 

   render() { 
        const hashtag = this.state.hashtag;
        const programs = this.state.programs;
    
        if (!hashtag || typeof hashtag === 'undefined' ||
            !programs || typeof programs === 'undefined') {
            return  <Loading />;
        }   

        const programData = programs.map((programs) => {
            return (
                <div className="pd-ls-lg">
                    <Col className="card-program-list" xs={{size: 12}} key={programs.slug}>
                        <Link to={`/programs/${programs.slug}`} style={{color: '#555'}}>
                            <Media className="image-program" src={programs.images_feature}/>
                            <Col style={{padding: '0px'}}>
                                <Progress color="success" value={programs.precentage}
                                    style={{ boxShadow: '0 10px 6px -6px #c3c3c3'}}
                                        className="progress-program">{programs.precentage}%</Progress>
                            </Col>
                            <Row style={{marginTop: '20px'}}>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Terkumpul
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}> 
                                        {programs.raised_format}
                                        </span>     
                                    </center>
                                </Col>
                                <Col style={{ borderRight: '2px solid #eee', borderLeft: '2px solid #eee'}}>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Donatur
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.donors_count}
                                        </span>     
                                    </center>
                                </Col>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Sisa
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.day_left} Hari
                                        </span>     
                                    </center>
                                </Col>
                            </Row>   
                            <br/> 
                            <Row>
                                <Col>
                                    <span className="title-green-program">{programs.title}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="program-description">
                                    {programs.description}
                                </Col>
                            </Row>
                        </Link>

                        <Link to={`/carts/${programs.id}`}>
                            <Row>
                                <MDBBtn className='btn btn-color-primary btn-program btn-infaq btn-block'
                                        style={{color: '#fff'}} block>
                                        <span style={{color: '#fff'}}>
                                            INFAQ
                                        </span>
                                </MDBBtn>
                            </Row>
                        </Link>
                    </Col>
                </div> 
            );
        });
 

        return (
            <Fragment>
                <Container> 
                    <Row>
                        <Media
                            className='image-program-detail'
                            src={hashtag.images_banner}
                            style={{height: '100%', width: '100%'}}
                        />
                    </Row>
                    <Row>
                        <Media
                            className='image-profile-hashtag'
                            src={hashtag.images_feature} 
                        />
                    </Row>
                    <Row>
                        <Col className='title text-green' 
                            style={{textAlign: 'center', letterSpacing: '5px',
                                paddingTop: '60px', paddingBottom: '20px' }}>
                            {hashtag.title}
                        </Col>
                    </Row> 
                    <hr className='line-home'/>
                    <Row>
                        <Col>
                            <span style={{textSize: '14px', textAlign: 'center'}}>
                                { ReactHtmlParser(hashtag.description) }
                            </span>
                        </Col>
                    </Row> 
                    <hr className='line-home'/>

                    <Row>
                        <Col className='title'>
                            Program Terkait
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} className='lines-title'/>
                    </Row>
                    {programData}  
                </Container>
            </Fragment>
       )
   }
}
 
export default Hashtag;
