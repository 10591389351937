import React, { Fragment, Component } from 'react';
import {Link} from 'react-router-dom';
import { Col, Container, Media, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBullhorn, faCalculator, faCubes, faHome, faSuitcase, faTimes, faHashtag} from '@fortawesome/free-solid-svg-icons'; 
import { MDBBtn } from "mdbreact";
import axios from '../../utils/axios';
import Loading from './Loading';

export default class Sidebar extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            hashtags: [],
            isLoading: false, 
            
        };
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/hashtag`);
 
        this.setState({hashtags: data, isLoading: false});
    } 
    
    closeNav() {
        let location = window.location.pathname;

        let result = location.split("/");

        if (result.length > 2) { 
            location.href = location.href  
        }

        document.getElementById("mySidepanel").style.width = "0";
    }
     
    render() {
        const {data} = this.state.hashtags;
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }  
        const hashtagData =  data.map((hashtags) => {
            return ( 
                <Row className='row-menu row-child-sidebar'>
                    <Col>
                        <Link key={hashtags.slug} className='bm-item menu-item'  
                        to={`/hashtag/${hashtags.slug}`} 
                        onClick={this.closeNav}>
                            <FontAwesomeIcon icon={faHashtag}/>
                            <span className='text-menu-sidebar'>{hashtags.title}</span>
                        </Link>
                    </Col>       
                </Row> ) 
        });

        return(
            <Fragment> 
                <div id="mySidepanel" className="sidepanel"> 
                    <Container>
                        <Row className="row-menu close-sidebar" style={{ borderBottomRightRadius: '90%' }} onClick={this.closeNav}>
                            <Col xs="3" onClick={this.closeNav}>
                                <span style={{ fontSize: '30px', fontWeight: 'bold', color: '#555555b5'}}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </span>
                            </Col> 
                        </Row>
                        <Row className='row-menu'>
                            <Col>
                                <a href='/'>
                                    <Media className='logo-sidebar' src='https://zakatpedia.com/images/logo.png'/>
                                </a>
                            </Col>
                        </Row>
                        
                        <Row className="row-btn-sidebar">
                            <Col xs='6' style={{paddingRight: '1px'}}> 
                                <Link to='/register' onClick={this.closeNav}> 
                                    <MDBBtn className="btn-regis-sidebar" block>Daftar</MDBBtn> 
                                </Link>
                            </Col>
                            <Col xs='6' style={{paddingLeft: '1px'}}>
                                <Link to='/login' onClick={this.closeNav}>
                                    <MDBBtn className='btn-login-sidebar' block>Masuk</MDBBtn>{' '}
                                </Link>
                            </Col>
                        </Row>

                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faHome}/>
                                    <span className='text-menu-sidebar'>Home</span>
                                </Link>
                            </Col>
                        </Row>
                        {/* <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <a className='bm-item menu-item' href='https://zakatpedia.com/qurban' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faBookmark}/>
                                    <span className='text-menu-sidebar'>Qurban<sup style={{color: '#84bd02', fontWeight: 'bold'}} > new</sup></span>
                                </a>
                            </Col>
                        </Row> */}
                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/services' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faSuitcase}/>
                                    <span className='text-menu-sidebar'>Layanan</span>
                                </Link>
                            </Col>
                        </Row>
                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/programs' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faCubes}/>
                                    <span className='text-menu-sidebar'>Program</span>
                                </Link>
                            </Col>
                        </Row>

                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/kalkulator-zakat' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faCalculator}/>
                                    <span className='text-menu-sidebar'>Kalkulator Zakat</span>
                                </Link>
                            </Col>
                        </Row>
                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/term-service' onClick={this.closeNav}>
                                    <FontAwesomeIcon icon={faBullhorn}/>
                                    <span className='text-menu-sidebar'>Syarat & Ketentuan</span>
                                </Link>
                            </Col>
                        </Row>
                        <hr/>
                        {hashtagData} 
                    </Container>
                </div>
            </Fragment>
        )
    }
} 