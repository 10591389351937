import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, CustomInput, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";
import Loading from '../Loading';

class ZakatPertanian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yields: 0, //Hasil Panen
            slug:props.slug,
            type_nisab: 'beras',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            beras: []
        };
        this.handleYieldsChange = this.handleYieldsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleYieldsChange(evt) {
        this.setState({yields: Number(evt.target.value)});
    }

    handleChange(event) {
        this.setState({
            size: event.target.value
        });
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);
       
        const dataBeras = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);

        this.setState({services: data.data.data, beras: dataBeras.data.data, isLoading: false});
    };
    render() {
        if ((!this.state.beras[0] && !this.state.beras[1]) || (typeof this.state.beras[0] && this.state.beras[1])  === 'undefined') {
            return <Loading />
        } 

        const harga_beras = this.state.beras[0].value; 
        const nisab_beras = this.state.beras[1].value; 
        const totalNisab = harga_beras * nisab_beras;

        const total_assets = this.state.yields * 10000;
        const zakatMaximum = total_assets * 10 / 100;
        const zakatMinimum = total_assets * 5 / 100;

        let validationZakat;

        if (total_assets >= totalNisab) {
            if (this.state.size === "min") {
                validationZakat =
                    <Fragment>
                        <FormGroup>
                            <Alert color="info" className="alert-calculator">
                                <span>JUMLAH ZAKAT PERTANIAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                                <br/>
                                <span>(5% dikali penghasilan kena zakat)</span>
                                <br/>
                                <span className="text-amount-zakat">{zakatMinimum.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <MDBBtn className="btn-infaq  btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </FormGroup>
                    </Fragment>;

            } else {
                validationZakat =
                    <Fragment>
                        <FormGroup>
                            <Alert color="info" className="alert-calculator">
                                <span>JUMLAH ZAKAT PERTANIAN YANG WAJIB DIBAYARKAN ADALAH:</span>
                                <br/>
                                <span>(10% dikali penghasilan kena zakat)</span>
                                <br/>
                                <span className="text-amount-zakat">{zakatMaximum.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <MDBBtn className="btn-infaq  btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </FormGroup>
                    </Fragment>;
            }
        } else {
            validationZakat =
                <Fragment>

                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        }

        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Nishab Zakat Pertanian : {nisab_beras} kg beras, </span>
                                <span>harga beras yang ditentukan dan disepakati </span>
                                <span>oleh IZI adalah senilai Rp. {harga_beras.toLocaleString(navigator.language, {minimumFractionDigits: 0})} per </span>
                                <span>kilogram sehingga nisab zakat Pertanian </span>
                                <span>saat ini adalah senilai : Rp. {totalNisab.toLocaleString(navigator.language, {minimumFractionDigits: 0})} </span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Total Hasil Panen dalam kilogram</Label>
                            <Input type="number" onChange={this.handleYieldsChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        <label>Saya mengelola ladang dengan</label>
                        <FormGroup> 
                            <CustomInput type="radio" id="label-radio-zakat"
                                         value="min"
                                         checked={this.state.size === "min"}
                                         onChange={this.handleChange}
                                         label="Diairi dengan bantuan alat pengangkut air / irigasi."/>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="radio" id="exampleCustomRadio2"
                                         value="max"
                                         checked={this.state.size === "max"}
                                         onChange={this.handleChange}
                                         label="Diairi menggunakan air hujan / tadah hujan." 
                                         className="label-radio-zakat" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="total_pendapatan">Total Penghasilan</Label>
                            <Input type="text" name="total_pendapatan" id="total_pendapatan"
                                   value={total_assets.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                   disabled/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default ZakatPertanian;
