import React, {Component, Fragment} from 'react';
import {Col, Media, Row} from 'reactstrap';
import axios from '../../../utils/axios';
import ReactHtmlParser from 'react-html-parser';
import Loading from '../../components/Loading';
import { MDBBtn } from "mdbreact"; 
import Moment from 'react-moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLightbulb, faChevronDown } from '@fortawesome/free-solid-svg-icons';  

export default class TabUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            programs: props.programs,
            updates: {
                id: '',
                slug: '',
                program_id: '',
                title: '',
                url_image: '',
                headline: '',
                content: '',
                is_publish: true,
                deleted_at: '',
                created_at: '',
                updated_at: ''
            },
            visible: 1,
            error: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 1};
        });
    }

    componentDidFetchData = async () => {
        try {
            this.setState({isLoading: true}); 
            
            const data = await axios.get(`/programs/${this.props.programs.id}/update`);

            this.setState({updates: data.data, isLoading: false});
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount = async () => {
        await this.componentDidFetchData();
    };

    render() {
        const {data} = this.state.updates; 
        
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const updateData = data.slice(0, this.state.visible).map((updates) => {

            const content = updates.content;

            return (
                <div key={updates.id} className="timeline-item">
                    <div className="timeline-icon">
                    </div>
                    <div className="timeline-content right">
                        <Col>
                            <Row>
                                <h5 style={{color: '#555'}}>{updates.title}</h5>
                            </Row>
                            <Row>
                                <span style={{fontSize: '12px'}}> 
                                    <Moment format={'dddd, DD MMMM YYYY'}>
                                        {updates.updated_at.date}
                                    </Moment>
                                </span><br/>
                            </Row>
                            <Row>
                                <Media src="https://i.ibb.co/7QpkyjV/lines.png" className="lines-title"/>
                            </Row>
                            <Row>
                                <Media className="img-update-detail" src={updates.url_image}/>
                            </Row>
                            <Row style={{marginTop: '20px', fontSize: '13px'}}>
                                {ReactHtmlParser(content)}
                            </Row>
                        </Col>
                    </div>
                </div>
            )
        });

        let loadMore;

        if (this.state.visible < data.length) {
            loadMore =
                <Fragment>
                    <MDBBtn className="pulsate-bck" onClick={this.loadMore} color="primary" 
                            style={{ 
                                width: '50px',
                                borderRadius: '50%',
                                height: '50px',
                                fontSize: '20px',
                                background: '#fff',
                                color: '#9ecc49',
                                border: '1px solid #9ecc49',
                                marginBottom: '90px',
                                margin: 'auto',
                                display: 'block'}}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </MDBBtn>
                </Fragment>
        }

        if (Object.keys(data).length === 0) {
            return (
                <Fragment>
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faLightbulb} size="4x" 
                                style={{ color: '#ecd223', marginTop: '30px', marginLeft: '43%' }}/>  
                        </Col> 
                    </Row>
                    <Row>
                        <Col style={{ padding: '30px', textAlign: 'center', fontSize: '14px', color: '#84BD00'}}>
                            <span style={{ letterSpacing: '2px', fontWeight: 'bold'}}>
                               Maaf, belum ada <br/>Update untuk Program ini.
                            </span>
                        </Col>
                    </Row> 
                </Fragment> 
            )
        }
        return (
            <Fragment>
                <div id="timeline">
                    {updateData}
                    <Row>
                        <Col xs="12">
                            {loadMore}
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }
}
