import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import axios from '../../utils/axios';
import {  Col, Container, Media, Row} from 'reactstrap';
import Loading from '../components/Loading';
import { MDBBtn } from "mdbreact";

class Lists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            services: [],
            isLoading: false,
            cursorPos: {},
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };
    }

    async componentDidMount() {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/services`);

        if (data.data.length < 1) {
            const messages = {...this.state.messages, show: true, success: false};
            this.setState({messages});
        }

        this.setState({services: data, isLoading: false});
    }
    handleClick = e => {
        e.stopPropagation();
        // Waves - Get Cursor Position
        let cursorPos = {
          top: e.clientY,
          left: e.clientX,
          time: Date.now() // time indicates particular clicks
        };
        this.setState({ cursorPos: cursorPos });
      };
    render() {
        const {data} = this.state.services;

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const serviceData = data.map((services) => {
            return (
                <Col key={services.id} xs='4' className='service-square-3' >
                    <Link to={`/services/${services.slug}`}>
                        <Row className='row-service-top'>
                            <Col xs={{size: 6, offset: 1}}>
                                <Media className='img-layanan' src={services.images_icon}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 12}}>
                                <div className='service-title-3'>{services.title}</div>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            );
        });

        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className='title'>
                            Semua Layanan
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} className='lines-title'/>
                    </Row> 
                    <Row className='row-service' >
                        {serviceData}
                    </Row>
                </Container>
                <Link to='/kalkulator-zakat'>
                    <MDBBtn className='ripple button-fixed btn-color-primary btn-block btn-flat' style={{ color:'#fff'}} block>
                        Hitung Zakat
                    </MDBBtn>
                </Link>
            </Fragment>
        )
    }

}

export default Lists;
