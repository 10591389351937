import React from 'react'; 
import axios from '../../utils/axios'; 
import {parse} from 'query-string'
import {Link, withRouter} from 'react-router-dom';
import 'moment/locale/id'; 
import {Card, CardBody, Col, Container, Media, Row} from 'reactstrap';
import lines from '../../assets/images/lines.png';
import TransferTankYou from './TransferThankYou';
import DefaultThankYou from './DefaultThankYou';
import VirtualAccountThankYou from './VirtualAccountThankYou';
import LinkAjaThankYou from './LinkAjaThankYou';
import Loading from '../components/Loading'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPaperPlane} from '@fortawesome/free-regular-svg-icons'; 
import { MDBBtn } from "mdbreact";  

const style = {
    buttonSave: {
        fontWeight: 'bold',
        fontSize: '14px',
        background: '#9ecc49',
        border: 'none'
    }
};

class ThankYou extends React.Component {
    state = {
        copied: {
            account: false,
            amount: false
        },
        va_number: '',
        donation: {}
    };

    async componentDidMount() {
        const {donation: id, va_number} = parse(this.props.location.search);

        if (id) {
            const {data: response} = await axios.get(`/donations/${id}`);

            const {data: donation} = response;

            this.setState({donation, va_number});
        }
    }

    onCopy = (name) => {
        this.setState({copied: {...this.state.copied, [name]: true}});

        setInterval(() => this.setState({copied: {...this.state.copied, [name]: false}}), 3000);
    };

    renderThankYou = (donation) => {
        switch (donation.transaction_type) {
            case 'TRANSFER' :
                return <TransferTankYou
                    donation={this.state.donation}
                    onCopy={this.onCopy}
                    copied={this.state.copied}
                />;
            case 'VA' :
                return <VirtualAccountThankYou
                    donation={this.state.donation}
                    onCopy={this.onCopy}
                    copied={this.state.copied}
                    va={this.state.va_number}
                />;
            case 'TCASH' :
                return <LinkAjaThankYou
                    donation={this.state.donation}
                />;
            default:
                return <DefaultThankYou/>;
        }
    };

    render() { 
        if (!this.state.donation.id) {
            return <Loading/>
        }

        return (
            <>
                <React.Fragment>
                    <Container>
                        <Row>
                            <Col className='title mt-4'>Konfirmasi</Col>
                        </Row>
                        <Row className='mb-2'>
                            <Media src={lines} className='lines-title'/>
                        </Row>
                        <Row className='d-flex align-items-center justify-content-center pd-ls' >
                            <Col className='text-center'>
                                <Card className='mb-3'>
                                    <CardBody>
                                        <FontAwesomeIcon style={{ color: '#84BD00' }} icon={faCheckCircle} size="3x"/> <br/><br/>
                                        <p style={{color: '#84BD00'}} className='font-weight-bold'>
                                            Terimakasih atas pembayaran Zakat, Infaq atau Shodaqoh anda di
                                            Zakatpedia.com.
                                        </p>
                                    </CardBody>
                                </Card>

                                {this.state.donation.id && this.renderThankYou(this.state.donation)}

                                <CardBody className='m-0 p-0 mt-3'> 
                                    <Link style={style.buttonSave} to={'/'}>
                                        <MDBBtn type='submit' style={style.buttonSave} block >
                                            <span style={{color: '#fff'}}>
                                                Selesai &nbsp;
                                                <FontAwesomeIcon icon={faPaperPlane}/>
                                            </span>
                                        </MDBBtn>
                                    </Link>
                                </CardBody>
                            </Col> 
                        </Row>
                        <Col style={{ marginBottom: '50px'}}>
                        </Col>
                    </Container>
                </React.Fragment>
            </>
        )
    }
}

export default withRouter(ThankYou);
