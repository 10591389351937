import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Media, Progress, Row} from 'reactstrap';
import axios from '../../../utils/axios';
import Slider from 'react-slick';
import Loading from '../../components/Loading';  
import LazyLoad from 'react-lazyload';

class ProgramPopular extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            programs: [],
            isLoading: false,
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/programs-popular`);

        if (data.data.length < 1) {
            const messages = {
                ...this.state.messages,
                show: true,
                success: false
            };

            this.setState({messages});
        }

        this.setState({programs: data, isLoading: false});
    };

    render() {
        const {data} = this.state.programs;

        const settings = {
            arrows: false,
            dots: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true
        };

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const programData = data.map((programs) => {
            return (
                <div className="box-program-col">
                    <Col className="card-program-list" xs={{size: 12}} key={programs.slug}>
                        <Link to={`/programs/${programs.slug}`} style={{color: '#555'}}>
                            <LazyLoad> 
                                <Media className="image-program" src={programs.images_feature}/>
                            </LazyLoad>
                            <Col style={{padding: '0px'}}>
                                <Progress color="success" value={programs.precentage} className="progress-program">{programs.precentage}%</Progress>
                            </Col>
                            <Row style={{marginTop: '20px'}}>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Terkumpul
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}> 
                                        {programs.raised_format}
                                        </span>     
                                    </center>
                                </Col>
                                <Col style={{ borderRight: '2px solid #eee', borderLeft: '2px solid #eee'}}>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Donatur
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.donors_count}
                                        </span>     
                                    </center>
                                </Col>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Sisa
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.day_left} Hari
                                        </span>     
                                    </center>
                                </Col>
                            </Row>   
                            <br/> 
                            <Row>
                                <Col className="title-program">
                                    <span className="title-green-program">{programs.title}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="program-description">
                                    {programs.description}
                                </Col>
                            </Row>
                        </Link>

                        <Link to={`/carts/${programs.id}`}>
                            <Row>
                                <Button
                                    className='btn btn-color-primary btn-program btn-infaq btn-block'
                                    style={{color: '#fff'}}
                                    block
                                >
                                    <span style={{color: '#fff'}}>INFAQ</span>
                                </Button>
                            </Row>
                        </Link>
                    </Col>
                </div>
                )

        });
        return (
            <Fragment>
                <center>
                    <Row>
                        <Col className="title">
                            Program Terpopuler
                        </Col>
                    </Row>
                    <Row>
                        <Media style={{marginLeft: 'auto', marginRight: 'auto'}}
                               src={require('../../../assets/images/lines.png')} className="lines-title"/>
                    </Row>
                </center>

                <Slider {...settings}>
                    {programData}
                </Slider>
            </Fragment>
        )
    }
}

export default ProgramPopular;
