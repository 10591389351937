import React from 'react'
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';
import numeral from 'numeral';

function CartSummary(props) {

    const calculateTotalAmount = (donation) => {
        const amount = parseInt(donation.amount) || 0;
        const codeUnique = parseInt(donation.code_unique);
        const serviceFee = parseInt(donation.service_fee);

        const total = amount + codeUnique + serviceFee;

        return numeral(total).format('0,0');
    };

    return (
        <Card className='rounded-0'>
            <CardHeader>
                <strong>Ringkasan Tagihan</strong>
            </CardHeader>
            <CardBody className='mt-1 pt-1 mb-1 pb-1 card-tagihan'>
                <Row>
                    <Col col={6}>Total Transaksi</Col>
                    <Col col={6}>
                        <span className='float-right'>{numeral(props.donation.amount).format('0,0')}</span>
                    </Col>
                </Row>
                <Row>
                    <Col col={6}>Kode Unik</Col>
                    <Col col={6}>
                        <span className='float-right'>{props.donation.code_unique}</span>
                    </Col>
                </Row>
                <Row>
                    <Col col={6}>Biaya Layanan</Col>
                    <Col col={6}>
                        <span className='float-right'>{numeral(props.donation.service_fee).format('0,0')}</span>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col col={6}>
                        <strong>Total Yang Dibayar</strong>
                    </Col>
                    <Col col={6}>
                        <strong className='float-right'>
                            {calculateTotalAmount({...props.donation})}
                        </strong>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    );
}

export default CartSummary;
