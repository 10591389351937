import React, {Component, Fragment} from 'react';

import Slider from 'react-slick';
import {Col, Media, Row} from 'reactstrap';

import payment__mandiri from '../../../assets/images/payment-method/mandiri.png';
import payment__bca from '../../../assets/images/payment-method/bca.png'; 
import payment__bniSyariah from '../../../assets/images/payment-method/bniSyariah.png'; 
import payment__gopay from '../../../assets/images/payment-method/gopay.png';
import payment__ibMuamalat from '../../../assets/images/payment-method/ibMuamalat.png';
import payment__mandiriSyariah from '../../../assets/images/payment-method/mandiriSyariah.png'; 
import payment__tcash from '../../../assets/images/payment-method/tcash.png';
import payment__virtualAccount from '../../../assets/images/payment-method/virtualAccount.png';
 
class PaymentMethod extends Component {
    render() {

        const settingPayment = {
            dots: false,   
            autoplay: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1
        };

        return (
            <Fragment>
                <Row> 
                    <span className='title-green'>Layanan Transaksi<br/> Zakatpedia</span>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Slider {...settingPayment} arrows={false}>
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__mandiri} className='img-payment'/>
                                </Row>
                            </Col>
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__bca} className='img-payment'/>
                                </Row>
                            </Col> 
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__bniSyariah} className='img-payment'/>
                                </Row>
                            </Col> 
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__gopay} className='img-payment'/>
                                </Row>
                            </Col>
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__ibMuamalat} className='img-payment'/>
                                </Row>
                            </Col>
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__mandiriSyariah} className='img-payment'/>
                                </Row>
                            </Col> 
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__tcash} className='img-payment'/>
                                </Row>
                            </Col>
                            <Col xs={{size: 11, offset: 1}} className='card-payment'>
                                <Row>
                                    <Media src={payment__virtualAccount} className='img-payment'/>
                                </Row>
                            </Col> 
                        </Slider>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default PaymentMethod;
