import React, {Fragment} from 'react';
import axios from '../../utils/axios';
import {Link, Redirect} from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import {isLoggedIn, setToken} from '../../utils/auth';
import FacebookAuth from './components/FacebookAuth';
import GoogleAuth from './components/GoogleAuth';
import LoadingCircle from '../components/LoadingCircle';
import Error from '../../utils/error';
import {
    Col,
    Container,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Media,
    Row
} from 'reactstrap';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            remember: false,
            isLoading: false,
            error: new Error(),
            isLoggedIn: isLoggedIn()
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const error = new Error(this.state.error);
        error.clear(name);

        this.setState({[name]: value, error});
    }

    onSubmit = async (event) => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const {email, password, remember} = this.state;

            const response = await axios.post('/auth/login', {email, password, remember});

            setToken(response.data.token);

            this.setState({isLoggedIn: isLoggedIn(), isLoading: false})
        } catch ({response}) {
            this.setState({error: new Error(response.data.errors), isLoading: false});
        }
    };

    render() {
        const {error} = this.state;

        if (this.state.isLoggedIn) {
            return <Redirect to="/"/>
        }

        let FormLogin;

        if (!this.state.isLoading) {
            FormLogin =
                <Col>
                    <Form onSubmit={this.onSubmit}>
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='email' className='mr-sm-2'>Email</Label>
                            <Input type='email' name='email' onChange={this.onChange} id='email'/>
                        </FormGroup>
                        <FormGroup className='mb-2 mr-sm-2 mb-sm-0 pd-ls'>
                            <Label for='password' className='mr-sm-2'>Password</Label>
                            <Input type='password' name='password' onChange={this.onChange} id='password'/>
                        </FormGroup>
                        <FormGroup>
                            <FormFeedback tooltip>You will not be able to see this</FormFeedback>
                            <FormText hidden={!error.any()}>
                                <span style={{color: '#dc3545'}}>{error.get('email')}</span>
                            </FormText>
                        </FormGroup>
                        <Row className=" pd-ls">
                            <Col>
                                <Row>
                                    <Col xs={{size: 12}}>
                                        <CustomInput
                                            type='checkbox'
                                            id='remember'
                                            name='remember'
                                            label='Ingat Saya'
                                            onChange={this.onChange}
                                            checked={this.state.remember}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Link to='/register'>
                                        <span className='text-green'>
                                             <p className="text-right">Daftar Sekarang</p>
                                        </span>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='8'/>
                            <Col>
                                <Media right src='https://i.ibb.co/FqQcjg2/square-right.png'
                                       className='square-right'/>
                            </Col>
                        </Row>
                        <Row className="pd-ls">
                            <Col>
                                <MDBBtn type='submit' className='btn-login pd-ls' block>
                                    Masuk
                                </MDBBtn>
                            </Col>
                        </Row>
                    </Form>
                    <Row className=' pd-ls'> 
                        <h4 className='line-vertical'>
                            <span className='text-login-or'>Atau</span>
                        </h4>
                    </Row>
                    <Row className="pd-ls">
                        <Col>
                            <GoogleAuth/>
                        </Col>
                    </Row>
                    <Row className="pd-ls">
                        <Col>
                            <FacebookAuth className='btn-facebook'/>
                        </Col>
                    </Row>

                </Col>
        } else {
            FormLogin = <LoadingCircle/>
        }

        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className='title'>
                            Masuk ke akun Anda
                        </Col>
                    </Row>
                    <Row>
                        <Media src='https://i.ibb.co/7QpkyjV/lines.png' className='lines-title'/>
                    </Row>
                    <Row style={{marginBottom: '100px'}}>
                        {FormLogin}
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default Login;
