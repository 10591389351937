import React, {Component, Fragment} from 'react';
import axios from '../../utils/axios';
import {Link} from 'react-router-dom';
import {Col, Container, Media, Progress, Row} from 'reactstrap';
import ModalCategory from './tabs/components/ModalCategory';
import Loading from '../components/Loading';
import {MDBBtn} from 'mdbreact';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Lists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            programs: [],
            visible: 5,
            error: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
            return {visible: prev.visible + 4};
        });
    }

    componentDidMount = async () => {
        try {
            this.setState({isLoading: true});

            const {data} = await axios.get(`/programs`);

            if (data.data.length < 1) {
                const messages = {
                    ...this.state.messages,
                    show: true,
                    success: false
                };
                this.setState({messages});
            }

            this.setState({programs: data, isLoading: false});
        } catch (error) {
            console.log(error);

            this.setState({isLoading: false});
        }
    };

    render() {
        const {data} = this.state.programs;

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        const programData = data.slice(0, this.state.visible).map((programs) => {
            return (
                <div className="pd-ls-lg">
                    <Col className="card-program-list" xs={{size: 12}} key={programs.slug}>
                        <Link to={`/programs/${programs.slug}`} style={{color: '#555'}}>  
                            <LazyLoadImage className="image-program" effect="blur" src={programs.images_feature} />  
                            <Col style={{padding: '0px'}}>
                                <Progress color="success" value={programs.precentage}
                                    style={{ boxShadow: '0 10px 6px -6px #c3c3c3'}}
                                        className="progress-program">{programs.precentage}%</Progress>
                            </Col>
                            <Row style={{marginTop: '20px'}}>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Terkumpul
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}> 
                                        {programs.raised_format}
                                        </span>     
                                    </center>
                                </Col>
                                <Col style={{ borderRight: '2px solid #eee', borderLeft: '2px solid #eee'}}>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Donatur
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.donors_count}
                                        </span>     
                                    </center>
                                </Col>
                                <Col>
                                    <center> 
                                        <span style={{fontSize:'11px'}}> 
                                            Sisa
                                        </span>
                                        <br/>
                                        <span style={{fontSize:'12px', fontWeight: 'bold'}}>
                                            {programs.day_left} Hari
                                        </span>     
                                    </center>
                                </Col>
                            </Row>   
                            <br/> 
                            <Row>
                                <Col>
                                    <span className="title-green-program">{programs.title}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="program-description">
                                    {programs.description}
                                </Col>
                            </Row>
                        </Link>

                        <Link to={`/carts/${programs.id}`}>
                            <Row>
                                <MDBBtn className='btn btn-color-primary btn-program btn-infaq btn-block'
                                        style={{color: '#fff'}} block>
                                        <span style={{color: '#fff'}}>
                                            INFAQ
                                        </span>
                                </MDBBtn>
                            </Row>
                        </Link>
                    </Col>
                </div> 
            );
        });

        let loadMore;

        if (this.state.visible < data.length) {
            loadMore =
                <Fragment>
                    <MDBBtn className="pulsate-bck" onClick={this.loadMore} color="primary" 
                            style={{ 
                                width: '50px',
                                borderRadius: '50%',
                                height: '50px',
                                fontSize: '20px',
                                background: '#fff',
                                color: '#9ecc49',
                                border: '1px solid #9ecc49',
                                marginBottom: '90px'}}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </MDBBtn>
                </Fragment>
        }

        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className="title">
                            Semua Program
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} style={{width: '130px', height: '20px'}}
                               className="lines-title"/>
                    </Row> 
                    {programData} 
                    <Row>
                        <Col>
                            <Row>
                                <Col style={{
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        display: 'flex'}}>
                                    {loadMore}
                                </Col>
                            </Row>
                        </Col>
                    </Row> 
                </Container>
                <ModalCategory/>
            </Fragment>
        )
    }
}

export default Lists;
