
import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom'
import {Col, Container, Media, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBullhorn,
    faCalculator,
    faCubes,
    faHandHoldingHeart,
    faHome,
    faSuitcase,
    faUserCog,
    faTimes,
    faSignOutAlt,
    faBookmark,
    faHashtag
} from '@fortawesome/free-solid-svg-icons';  
import axios from '../../../utils/axios';
import {isLoggedIn} from '../../../utils/auth';
import {Redirect} from 'react-router-dom'; 
import Loading from '../../components/Loading';

export default class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            hashtags: [],
            isLoggedIn: isLoggedIn(),
            redirect: false, 
        };
    } 

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/hashtag`);
 
        this.setState({hashtags: data, isLoading: false});
    } 

    closeNav() {
        let location = window.location.pathname;

        let result = location.split("/");

        if (result.length > 2) { 
            location.href = location.href  
        }

        document.getElementById("sidebar").style.width = "0";  
    }

    logout = () => {
        localStorage.removeItem('token');
        this.setState({redirect: true});

        document.getElementById("sidebar").style.width = "0"; 
    };

    render() {
        const {data} = this.state.hashtags;
        if (!data || typeof data === 'undefined') {
            return <Loading />
        }
        const hashtagData = data.map((hashtags) => {
            return ( 
                <Row className='row-menu row-child-sidebar'>
                    <Col>
                        <Link key={hashtags.slug} className='bm-item menu-item' to={`/hashtag/${hashtags.slug}`} onClick={this.closeNav}>
                            <FontAwesomeIcon icon={faHashtag}/>
                            <span className='text-menu-sidebar'>{hashtags.title}</span>
                        </Link>
                    </Col>       
                </Row> ) 
        });

        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }
        return(
            <Fragment>
                <div id="sidebar" className="sidepanel"> 
                    <Container>
                        <Row>
                            <Col style={{height: '130px'}}>
                            <Row className="row-menu close-sidebar" style={{ borderBottomRightRadius: '90%' }} onClick={this.closeNav}>
                                <Col xs="3" onClick={this.closeNav}>
                                    <span style={{ fontSize: '30px', fontWeight: 'bold', color: '#555555b5'}}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </span>
                                </Col> 
                            </Row>
                                <Link to='/profiles/settings' onClick={this.closeNav}>
                                    <Row>
                                        <Col xs={{size: 3, offset:1}}>
                                            <Media className="img-profile-sidebar" src={this.props.avatar ? this.props.avatar : require('../../../assets/images/users.png')} />
                                        </Col>
                                        <Col xs={{size: 7}}>
                                            <Row className="text-sidebar-name">
                                                {this.props.name}
                                            </Row>
                                            <Row className="text-sidebar-email">
                                                {this.props.email}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col style={{paddingTop: '12px'}}>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faHome}/>
                                            <span className='text-menu-sidebar'>Home</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <a className='bm-item menu-item' href='https://zakatpedia.com/qurban' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faBookmark}/>
                                            <span className='text-menu-sidebar'>Qurban<sup style={{color: '#84bd02', fontWeight: 'bold'}} > new</sup></span>
                                        </a>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/services' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faSuitcase}/>
                                            <span className='text-menu-sidebar'>Layanan</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/programs' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faCubes}/>
                                            <span className='text-menu-sidebar'>Program</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/kalkulator-zakat' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faCalculator}/>
                                            <span className='text-menu-sidebar'>Kalkulator Zakat</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/profiles/donation' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faHandHoldingHeart}/>
                                            <span className='text-menu-sidebar'>Donasi Saya</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/profiles/settings' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faUserCog} />
                                            <span className='text-menu-sidebar'>Pengaturan</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className='row-menu row-child-sidebar'>
                                    <Col>
                                        <Link className='bm-item menu-item' to='/term-service' onClick={this.closeNav}>
                                            <FontAwesomeIcon icon={faBullhorn}/>
                                            <span className='text-menu-sidebar'>Syarat & Ketentuan</span>
                                        </Link>
                                    </Col>
                                </Row>  
                            </Col>
                        </Row>  
                        <hr/>
                        {hashtagData}
                        <hr/>
                        <Row className='row-menu row-child-sidebar'>
                            <Col>
                                <Link className='bm-item menu-item' to='/' onClick={this.logout}>
                                    <FontAwesomeIcon icon={faSignOutAlt}/>
                                    <span className='text-menu-sidebar'>Keluar</span>
                                </Link>
                            </Col>
                        </Row> 
                    </Container> 
                </div>
            </Fragment>
        )
    }
} 
