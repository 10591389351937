import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Col, Media, Navbar, Row} from 'reactstrap';
import Sidebar from './Sidebar';
import {default as GuestSidebar} from '../../components/Sidebar';
import {isLoggedIn} from '../../../utils/auth';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons';

export default class Header extends Component {
    
    openNav = () => {
        document.getElementById("sidebar").style.width = "100%"; 
    };

    openSidebar = () => {
        document.getElementById("mySidepanel").style.width = "100%";
    };

    render() { 
        return (
            <Navbar className="navbar" expand='md' sticky='top'>
                <Row>
                    <Col>
                        {isLoggedIn() ?
                            <button className="openbtn" onClick={this.openNav}>
                                <FontAwesomeIcon icon={faBars}/>
                            </button>
                            :
                            <button className="openbtn" onClick={this.openSidebar}>
                                <FontAwesomeIcon icon={faBars}/>
                            </button>
                        }
                    </Col>
                    <Col>
                        <Link to="/">
                            <Media className='logo-header' src={require('../../../assets/images/logo.png')} />
                        </Link>
                    </Col>
                </Row>

                {isLoggedIn() ? <Sidebar name={this.props.name} email={this.props.email} avatar={this.props.avatar}/> : <GuestSidebar/>}
            </Navbar>
        )
    }
}
