import React, {Component, Fragment} from 'react';
import {Col, Container, Media, Row} from 'reactstrap';
import axios from '../../utils/axios';
import Loading from '../components/Loading';
import {Link} from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import Moment from 'react-moment'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { 
    faHandHoldingHeart, 
    faHands, 
    faShoppingCart, faChevronDown
} from '@fortawesome/free-solid-svg-icons'; 

export default class ListDonation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            donations: [],
            isLoading: false,
            visible: 5,
            error: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 4};
        });
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/profiles/donations`);

        if (data.data.length < 1) {
            const messages = {
                ...this.state.messages,
                show: true,
                success: false
            };

            this.setState({messages});
        }

        this.setState({donations: data, isLoading: false});
    };

    render() {
        const {data} = this.state.donations;
         
        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }
        if (Object.keys(data).length === 0) {
            return ( 
                <Col>
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faShoppingCart} size="4x" className="cart-empty"/>  
                        </Col> 
                    </Row>
                    <Row>
                        <Col style={{    padding: '30px', textAlign: 'center', fontSize: '14px', color: '#84BD00'}}>
                            <span>
                                Keranjang zakat anda masih kosong.<br/>Tunaikan <strong>Zakat</strong> atau <strong>Donasi</strong> Program.
                            </span>
                        </Col>
                    </Row> 
                    <Row style={{textAlign: 'center', position: 'fixed' ,bottom: '0px', width: '100%'}}> 
                        <Col className="rippleOut"> 
                            <Link to='/services'>
                                <span style={{color: '#fff'}}>
                                    Zakat &nbsp; <FontAwesomeIcon icon={faHands}/> 
                                </span>
                            </Link> 
                        </Col> 
                        <Col className="rippleOut"> 
                            <Link to='/programs'>
                                <span style={{color: '#fff'}}>
                                    Donasi &nbsp; <FontAwesomeIcon icon={faHandHoldingHeart}/> 
                                </span>
                            </Link> 
                        </Col>  
                    </Row>
                </Col>
            )
        }

        const CardDonation = data.slice(0, this.state.visible).map((item) => {
            let cardStatus;
            if (item.status === 'PAID') {
                cardStatus = <Col xs="5" className="card-paid">
                    {item.status}
                </Col>;
            } else if (item.status === 'UNPAID') {
                cardStatus =
                    <Col xs="5" className="card-unpaid">
                        {item.status}
                    </Col>;
            } else {
                cardStatus =
                    <Col xs="5" className="card-cancel">
                        {item.status}
                    </Col>;
            }

        
            return ( 
                <details style={{padding: '30px'}} key={item.id}>
                    <summary>
                        <Row>
                            {cardStatus}
                            <Col xs={{size: 10, offset: 4}}>
                                <span style={{color: ' #555', fontWeight: 'bold'}}>
                                    No. Transaksi :
                                </span><br/>
                                <span style={{color: ' #9ecc49', fontWeight: 'bold'}}>
                                    <u>{item.transaction_number}</u>
                                </span>
                            </Col>
                        </Row>
                    </summary>
                    <br/>
                    <span style={{color: ' #555', fontWeight: 'bold'}}>
                        Tanggal :
                    </span>
                    <span style={{float: 'right'}}>
                        <Moment format="DD/MM/YYYY">
                            {item.transaction_date}
                        </Moment>
                    </span>
                    <br/><p/>
                    <span style={{color: ' #555', fontWeight: 'bold'}}>Detail:</span>
                    {item.details.map((d, i) => (
                        <ol key={i}>
                            <li>
                                <i> {d.program.title} : </i>
                                <br/>
                                <span style={{float: 'right', fontWeight: 'bold', color: '#9ecc49'}}>
                                    {d.total_amount.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                                </span>
                            </li>
                        </ol>
                    ))}
                    <br/>
                    <span style={{color: ' #555', fontWeight: 'bold'}}>
                        Kode Unik :
                    </span>
                    <span style={{float: 'right', color: '#9ecc49', fontWeight: 'bold'}}>
                        {item.code_unique}
                    </span><br/>
                    <hr style={{
                        border: '0',
                        borderTop: '3px double #8c8c8c'
                    }}/>
                    <span style={{color: ' #555', fontWeight: 'bold'}}>
                        Total :
                    </span>
                    <span style={{float: 'right', color: '#9ecc49', fontWeight: 'bold'}}>
                        Rp. {item.total_amount.toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                    </span>
                    <hr style={{
                        border: '0',
                        borderTop: '3px double #8c8c8c'
                    }}/>
                </details>
            )
        });

        let loadMore;

        if (this.state.visible < data.length) {
            loadMore = 
                <Fragment>
                    <MDBBtn className="pulsate-bck btn-pull" onClick={this.loadMore} color="primary" 
                            style={{ 
                                width: '50px',
                                borderRadius: '50%',
                                height: '50px',
                                fontSize: '20px',
                                background: '#fff',
                                color: '#9ecc49',
                                border: '1px solid #9ecc49',
                                marginBottom: '90px'}}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </MDBBtn>
                </Fragment>
        }

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col className="title">
                            Daftar Donasi Anda
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} className="lines-title"/>
                    </Row>
                    <Col className="col-list-donation">
                        {CardDonation}
                    </Col>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={{size: 10, offset:2}}>
                                    {loadMore}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{textAlign: 'center', position: 'fixed' ,bottom: '0px', width: '100%'}}> 
                        <Col className="rippleOut"> 
                            <Link to='/services'>
                                <span style={{color: '#fff'}}>
                                    Zakat &nbsp; <FontAwesomeIcon icon={faHands}/> 
                                </span>
                            </Link> 
                        </Col> 
                        <Col className="rippleOut"> 
                            <Link to='/programs'>
                                <span style={{color: '#fff'}}>
                                    Donasi &nbsp; <FontAwesomeIcon icon={faHandHoldingHeart}/> 
                                </span>
                            </Link> 
                        </Col>  
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
