import React, {Component, Fragment} from 'react';
import axios from '../../../utils/axios';
import LoadingCircle from '../../components/LoadingCircle';
import {Alert, Col, Form, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import Errors from '../../../assets/js/plugins/error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { MDBBtn } from "mdbreact";

export default class PasswordInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isLoggedIn: false,
            errors: new Errors(),
            user: {
                id: '',
                password: '',
                password_confirmation: ''
            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            },
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const users = {...this.state.users, [name]: value};

        const errors = new Errors(this.state.errors.getErrors());
        errors.clear(name);

        this.setState({errors, users});
    };

    onHandleSubmit = async (event) => {
        try {
            event.preventDefault();

            this.setState({isLoading: true});

            const data = await axios.put(`/profiles/update/password`, {...this.state.users});

            const messages = {...this.state.messages, show: true, success: data.success, message: data.message};

            this.setState({isLoading: false, messages});
        } catch (e) {
            this.setState({isLoading: false});
        }
    };

    onDismiss() {
        this.setState({visible: false});
    }

    render() {
        const {messages} = this.state;

        let FormUpdate; 
         
        if (!this.state.isLoading)
        { 
            FormUpdate = 
                <Col xs={{size: 10, offset: 1}}>
                    <Form onSubmit={this.onHandleSubmit}>
                        <FormGroup>
                            <Label for='exampleText'>Password Baru</Label>
                            <Input type='password' name='password' id='password' onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='exampleText'>Konfirmasi Password</Label>
                            <Input type='password' name='password_confirmation' id='password_confirmation'
                                onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <MDBBtn type='submit' className='btn-save-profile'> Simpan</MDBBtn>
                        </FormGroup>
                    </Form>
                </Col>
        } else {
            FormUpdate = <LoadingCircle />
        }

        return (
            <Fragment>
                <Row>
                    <Col xs='8'/>
                    <Col>
                        <Media right src={require('../../../assets/images/square-right.png')} className='square-right'/>
                    </Col>
                </Row>
                <Row>
                    <Col className='title'>
                        Ubah Password
                    </Col>
                </Row>
                <Row>
                    <Media src={require('../../../assets/images/lines.png')} className='lines-title'/>
                </Row>

                {messages.show &&
                <div>
                    <Alert color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                        <FontAwesomeIcon icon={faCheckCircle}/> &nbsp;
                        Berhasil disimpan !
                    </Alert>
                </div>
                }
                
                <Row className='row-info-profile'>
                    {FormUpdate}
                </Row>
            </Fragment>
        )
    }
}
