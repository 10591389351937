import decode from 'jwt-decode'

export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => localStorage.getItem('token');

export const session = () => {
    const token = getToken();

    if (token) {
        const {id, email, name, avatar} = decode(token);

        return {id, email, name, avatar}
    }
    return {}
};

export const isLoggedIn = () => {
    const token = getToken(); 
    if (token) {
        const {exp} = decode(token);

        return (exp > Date.now() / 1000)
    }

    return false;
};
