import React, {Component, Fragment} from 'react';
import {Alert, Col, CustomInput, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import {Link} from 'react-router-dom';
import { MDBBtn } from "mdbreact";

class ZakatHadiah extends Component {
    constructor(props) {
        super(props);
        this.state = {
            income: 0,
            slug:props.slug,
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        };
        this.handleIncomeChange = this.handleIncomeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleIncomeChange(evt) {
        this.setState({income: Number(evt.target.value)});
    }

    handleChange(event) {
        this.setState({
            size: event.target.value
        });
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        this.setState({services: data.data.data, isLoading: false});
    };
    render() {
        const zakatMaximum = this.state.income * 20 / 100;
        const zakatMiddle = this.state.income * 10 / 100;
        const zakatMinimum = this.state.income * 2.5 / 100;
        const sizeZakat = this.state.size;
        const {services} = this.state;
        let validationZakat;

        if (sizeZakat === "middle") {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT HADIAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(10% dikali penghasilan kena zakat)</span><br/>
                            <span>Rp.{zakatMiddle.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakatMiddle}`} }}>
                            <MDBBtn className="btn-infaq" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;

        } else if (sizeZakat === "min") {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT HADIAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(2,5% dikali penghasilan kena zakat)</span><br/>
                            <span>Rp.{zakatMinimum.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakatMinimum}`} }}>
                            <MDBBtn className="btn-infaq" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;

        } else if (sizeZakat === 'max') {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT HADIAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>(20% dikali penghasilan kena zakat)</span><br/>
                            <span>Rp.{zakatMaximum.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakatMaximum}`} }}>
                            <MDBBtn className="btn-infaq" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <MDBBtn className="btn-infaq" block>
                            INFAQ
                        </MDBBtn>
                    </FormGroup>
                </Fragment>;
        }
        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">
                                <span>Kadar zakat yang dikeluarkan tergantung oleh jerih payah kita dalam mendapatkannya.</span>
                                <br/>
                                <ul>
                                    <li>
                                        <b>20% :</b> Apabila mendapakannya dengan tidak mengeluarkan jerih payah.
                                    </li>
                                    <li>
                                        <b>10% :</b> Apabila mendapakannya dengan minim jerih payah.
                                    </li>
                                    <li>
                                        <b>2.5% :</b> Apabila mendapakannya dengan usaha dan jerih payah.
                                    </li>
                                </ul>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Total Hadiah yang didapat (rupiah) </Label>
                            <Input type="number" onChange={this.handleIncomeChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="Rp.0,-"/>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="radio" id="exampleCustomRadio"
                                         value="max"
                                         checked={this.state.size === "max"}
                                         onChange={this.handleChange}
                                         label="Saya mendapakannya dengan tidak mengeluarkan jerih payah."/>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="radio" id="exampleCustomRadio2"
                                         value="middle"
                                         checked={this.state.size === "middle"}
                                         onChange={this.handleChange}
                                         label="Saya mendapakannya dengan minim jerih payah."/>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput type="radio" id="exampleCustomRadio3"
                                         value="min"
                                         checked={this.state.size === "min"}
                                         onChange={this.handleChange}
                                         label="Saya mendapakannya dengan usaha dan jerih payah."/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )

    }
}

export default ZakatHadiah;
