import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";

class Fidyah extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fidyah: 0,
            number_of_days: 0, //Jumlah hari tidak berpuasa
            one_day_fee: 0, // Jumlah Biaya makan dalam 1 hari
            slug:props.slug,
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        };
        this.handleNumberDaysChange = this.handleNumberDaysChange.bind(this);
        this.handleDayFeeChange = this.handleDayFeeChange.bind(this);
    }

    handleNumberDaysChange(evt) {
        this.setState({number_of_days: Number(evt.target.value)});
    }

    handleDayFeeChange(evt) {
        this.setState({one_day_fee: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        this.setState({services: data.data.data, isLoading: false});
    };

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        this.setState({services: data.data.data, isLoading: false});
    };
    render() {
        const fidyah = this.state.number_of_days * this.state.one_day_fee;
        let fidyahValidation;
        const {services} = this.state;

        if (fidyah) {
            fidyahValidation =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH FIDYAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>Rp.{fidyah.toLocaleString(navigator.language, {minimumFractionDigits: 0})}</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${fidyah}`} }}>
                            <MDBBtn className="btn-infaq" block>
                                BAYAR FIDYAH
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            fidyahValidation =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH FIDYAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span>Rp.0  </span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        }

        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah Hari tidak berpuasa</Label>
                            <Input type="number" onChange={this.handleNumberDaysChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Biaya makan dalam satu hari (Rupiah)</Label>
                            <Input type="number" onChange={this.handleDayFeeChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        {fidyahValidation}
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default Fidyah;
