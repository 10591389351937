import React from 'react';
import {Redirect} from 'react-router-dom';
import axios from '../../../utils/axios';
import {isLoggedIn, setToken} from '../../../utils/auth';
import {FACEBOOK_APP_ID} from '../../../utils/env';

import { FacebookProvider, LoginButton } from 'react-facebook';

class FacebookAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loginError: false,
            redirect: false,
            isLoggedIn: false,
            users: {
                name: '',
                provider: '',
                email: '',
                provider_user_id: ''
            },
        };
        this.socialite = this.socialite.bind(this);
    }

    socialite = (data) => {
        this.setState({isLoading: true});
        const avatar = data.profile.picture.data.url;
        const name = data.profile.name;
        const email = data.profile.email;
        const userID = data.profile.id;

        const users = {name, provider: 'facebook', email, provider_user_id: userID, avatar: avatar};

        axios.post('/auth/socialite', {...users})
            .then(response => {

                setToken(response.data.token);

                this.setState({
                    isLoading: false,
                    messages: {
                        show: true,
                        success: response.success,
                        message: response.message
                    }
                });

                this.setState({isLoggedIn: isLoggedIn(), isLoading: false})
            })
            .catch(error => {
                console.log(error);
                this.setState({isLoading: false});
            });

    };

    handleError = e => console.log(e);

    render() {
        if (this.state.isLoggedIn) {
            return (
                <Redirect to="/"/>
            )
        }

        return (
            <FacebookProvider appId={FACEBOOK_APP_ID} icon={''}>
                <LoginButton
                    className="btn-facebook"
                    scope="email"
                    onCompleted={data => this.socialite(data)}
                    onError={this.handleError}
                    icon={''}
                >
                    <span>Login via Facebook</span>
                </LoginButton>
            </FacebookProvider>
        )
    }
}

export default FacebookAuth;
