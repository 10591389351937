import {default as http} from 'axios';
import {API_END_POINT} from './env';
import {getToken} from './auth';

const axios = http.create({
    baseURL: API_END_POINT
});

axios.interceptors.request.use(config => {
        const token = getToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config;
    },
    error => Promise.reject(error)
);


export default axios;
