import React from 'react';
import {Redirect} from 'react-router-dom';
import GoogleLogin from 'react-google-login'; 
import axios from '../../../utils/axios';
import {isLoggedIn, setToken} from '../../../utils/auth';
import {GOOGLE_CLIENT_ID} from '../../../utils/env';
import { MDBBtn } from "mdbreact";

class GoogleAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loginError: false,
            redirect: false,
            isLoggedIn: false,
            users: {
                name: '',
                provider: '',
                email: '',
                provider_user_id: ''
            },
        };
        this.socialite = this.socialite.bind(this);
    }

    socialite = (response) => {
        this.setState({isLoading: true});

        const {name, email, googleId, imageUrl} = response.profileObj;

        const users = {name, provider: 'google', email, provider_user_id: googleId, avatar: imageUrl};

        axios.post('/auth/socialite', {...users})
            .then(response => {

                setToken(response.data.token);

                this.setState({
                    isLoading: false,
                    messages: {
                        show: true,
                        success: response.success,
                        message: response.message
                    }
                });

                this.setState({isLoggedIn: isLoggedIn(), isLoading: false})
            })
            .catch(error => {
                console.log(error);
                this.setState({isLoading: false});
            });

    };

    render() {
        if (this.state.isLoggedIn) {
            return (
                <Redirect to="/"/>
            )
        }
        return (
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                onSuccess={response => this.socialite(response)}
                onFailure={error => console.log(error)}
                render={renderProps => (
                    <MDBBtn className='btn-google' block onClick={renderProps.onClick}>
                        Login with Google
                    </MDBBtn>)}
            />
        )
    }
}

export default GoogleAuth;
