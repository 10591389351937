import React, {Component, Fragment} from 'react';

import Loading from './Loading';
import axios from '../../utils/axios'; 
import ZakatEmas from './calculator/ZakatEmas';
import ZakatPerniagaan from './calculator/ZakatPerniagaan';
import ZakatSewaAset from './calculator/ZakatSewaAset';
import Fidyah from './calculator/Fidyah';
import ZakatHadiah from './calculator/ZakatHadiah';
import ZakatFitrah from './calculator/ZakatFitrah';
import ZakatPerak from './calculator/ZakatPerak';
import ZakatTabungan from './calculator/ZakatTabungan';
import ZakatPertanian from './calculator/ZakatPertanian';
import ZakatPendapatan from './calculator/ZakatPendapatan';
import {Col, Container, Form, FormGroup, Label, Row} from 'reactstrap'; 
import InfaqSodaqoh from './calculator/InfaqSodaqoh';

export default class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slug: 'zakat-profesi',
            selected: '',
            query: '',
            services: [],
            isLoading: false,
            messages: {
                show: false,
                success: true,
                message: 'No data to show'
            }
        };

        this.handleSelect = this.handleSelect.bind(this)
    }

    async componentDidMount() {
        this.setState({isLoading: true});

        const {data} = await axios.get(`/services`);

        if (data.data.length < 1) {
            const messages = {...this.state.messages, show: true, success: false};
            this.setState({messages});
        }

        this.setState({services: data, isLoading: false});
    }

    handleSelect(e) {
        this.setState({slug: e.target.value})
    }

    render() {
        const {data} = this.state.services;

        if (!data || typeof data === 'undefined') {
            return <Loading/>
        }

        let formCalculate;

        if (this.state.slug === 'zakat-perniagaan') {
            formCalculate = (<ZakatPerniagaan slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-emas') {
            formCalculate = (<ZakatEmas slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-investasi') {
            formCalculate = (<ZakatSewaAset slug={this.state.slug}/>)
        } else if (this.state.slug === 'fidyah') {
            formCalculate = (<Fidyah slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-hadiah') {
            formCalculate = (<ZakatHadiah slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-fitrah') {
            formCalculate = (<ZakatFitrah slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-perak') {
            formCalculate = (<ZakatPerak slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-tabungan') {
            formCalculate = (<ZakatTabungan slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-pertanian') {
            formCalculate = (<ZakatPertanian slug={this.state.slug}/>)
        } else if (this.state.slug === 'zakat-profesi') {
            formCalculate = (<ZakatPendapatan slug={this.state.slug}/>)
        } else if (this.state.slug === 'infaq-sedekah') {
            formCalculate = ( <InfaqSodaqoh slug={this.state.slug}/>  ); 
        } else {
            formCalculate = (<p>Tidak tersedia Untuk Zakat Peternakan</p>)
        }

        const serviceData = data.map((services) => {
            return (
                <option
                    key={services.id}
                    name={services.id}
                    value={services.slug}>
                    {services.title}
                </option>
            );
        });

        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col className='calculator'>
                            <Row>
                                <Col>
                                    <Form>
                                        <FormGroup>
                                            <Label for='exampleSelect'>Hitung Zakat</Label>
                                            <select
                                                onChange={this.handleSelect}
                                                className='select-component'
                                                value={this.state.slug}
                                            >
                                                {serviceData}
                                            </select>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            {formCalculate}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
