import React, { Fragment } from 'react';  
import axios from '../../../utils/axios'; 
import {parse} from 'query-string';

class Iframe extends React.Component {

    state = { 
        donation        : {},
        back_url        : '',
        code_ovo        : '503',
        product_ovo     : 'OVO',
        code_jenius     : '075',
        product_jenius  : 'JENIUSIB',
        key             : '5485ef2f1999d74a05b36a99fc4558d1'
    };

    async componentDidMount() {
        const {donation: id} = parse(this.props.location.search); 
        if (id) {
            const {data: response} = await axios.get(`/donations/${id}`);

            const {data: donation} = response; 
            
            this.setState({donation, back_url : `https://m.zakatpedia.com/carts/success?donation=${id}` });
        }
        const script = document.createElement("script");

        script.src = "https://sandbox-kit.espay.id/public/signature/js";
        
        script.async = true;
    
        document.body.appendChild(script);
    }

    handleFileLoad = () => function () { 
        var SGOSignature;
        var data = {
            paymentId: this.state.donation.transaction_number,
            key: this.state.key, 
            backUrl: encodeURIComponent (this.state.back_url), 
            bankCode: this.state.transaction_type === 'OVO' ? this.state.code_ovo : this.state.code_jenius,
            bankProduct: this.state.transaction_type === 'OVO' ? this.state.product_ovo : this.state.product_ovo
        },
            sgoPlusIframe = document.getElementById("sgoplus-iframe");
        if (sgoPlusIframe !== null) {
            sgoPlusIframe.src = SGOSignature.getIframeURL(data);
        }
            SGOSignature.receiveForm();
    };
    render() { 
        return (
            <Fragment> 
                 <iframe id='sgoplus-iframe'  frameBorder={0} onLoad={this.handleFileLoad}></iframe>
            </Fragment>
        );
    }
}

export default Iframe;
