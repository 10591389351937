import React from 'react';
import {Col, Container, Media, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faExclamationCircle,
    faExclamationTriangle,
    faHandHoldingHeart,
    faUsers
} from '@fortawesome/free-solid-svg-icons';


function TermService() {
    return (
        <div>
            <Container>
                <Row>
                    <Col className="title">
                        Syarat & Ketentuan
                    </Col>
                </Row>
                <Row>
                    <Media src={require('../../assets/images/lines.png')} className="lines-title"/>
                </Row>
                <Row>
                    <Col xs="8"/>
                    <Col>
                        <Media right src={require('../../assets/images/square-right.png')} className="square-right"
                               style={{marginTop: '-75px'}}/>
                    </Col>
                </Row>
                <Row>
                    <details>
                        <summary>
                            <Col xs={{size: 12}} className="title-term-service">
                                <FontAwesomeIcon icon={faUsers} style={{marginRight: '5px'}}/>
                                <span><b> Umum</b></span>
                            </Col>
                        </summary>
                        <br/>
                        <Col xs={{size: 12}} className="content-term-service">
                            <span style={{color: '#9ecc49'}}><b><i>Zakatpedia.com</i></b></span> adalah Platform
                            pembayaran zakat, Infak dan
                            Sedekah online yang mudah dan memudahkan.
                            Jenis pembayaran pada situs ini terdapat 2 jenis yaitu :<p/>
                            <ol className="ol-number">
                                <li>
                                    <b><u>Layanan yang terdiri, atas:</u></b>
                                    <ol className="ol-lower-alpha">
                                        <li>Zakat Profesi,</li>
                                        <li>Zakat Tabungan,</li>
                                        <li>Zakat Emas, Zakat Perak,</li>
                                        <li>Zakat Perniagaan,</li>
                                        <li>Zakat Sewa Aset,</li>
                                        <li>Zakat Hadiah,</li>
                                        <li>Zakat Fitrah,</li>
                                        <li>Zakat Perternakan,</li>
                                        <li>Zakat Pertanian,</li>
                                        <li>Infaq/Sedekah, dan Fidyah.</li>
                                    </ol>
                                </li>
                                <br/>
                                <li>
                                    <b><u>Program yang terdiri atas kategori :</u></b>
                                    <ol className="ol-lower-alpha">
                                        <li>Pendidikan,</li>
                                        <li>Kesehatan,</li>
                                        <li>Dakwah,</li>
                                        <li>Ekonomi, dan</li>
                                        <li>Sosial.</li>
                                    </ol>
                                </li>
                            </ol>
                            <b>
                                <FontAwesomeIcon icon={faExclamationCircle} style={{color: '#9ecc49'}}/>
                                &nbsp;
                                <span style={{color: '#9ecc49'}}>
                                    <u>Kewajiban Pengguna Situs :</u>
                                </span>
                            </b> <p/>
                            <ol className="ol-number">
                                <li>
                                    <b>Menyatakan diri</b> sebagai seseorang yang mengetahui hukum
                                    dan norma-norma yang berlaku.
                                </li>
                                <li>
                                    <b>Dapat mempertanggung jawabkan</b> setiap hal-hal/tindakan yang dilakukan yang
                                    bersifat
                                    kelalaian/kekhilafan atas syarat dan ketentuan penggunaan Situs.
                                </li>
                                <li>
                                    <b>Wajib memberikan data atau informasi </b>
                                    dengan benar tidak menyesatkan.
                                </li>
                            </ol>
                            <b>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#cc4949'}}/>
                                &nbsp;
                                <span style={{color: '#cc4949'}}>
                                    <u>Larangan Pengguna Situs  :</u>
                                </span>
                            </b> <p/>
                            <ol className="ol-number">
                                <li>
                                    <b>Dilarang menginformasi dan memalsukan </b>
                                    data atau informasi Situs kepada orang lain.
                                </li>
                                <li>
                                    <b>Dilarang melakukan dan/atau menyuruh</b> pihak lain untuk melakukan tindakan
                                    apapun yang dapat menyebabkan pelanggaran terhadap sebagian atau seluruh hak
                                    kekayaaan
                                    intelektual dari Pengelola Situs maupun Pengguna Situs lainnya.
                                </li>
                                <li>
                                    <b>Dilarang melakuakan pengrusakan,</b>
                                    menganggu atau membatasi system pada situs.
                                </li>
                                <li>
                                    <b>Dilarang melakukan
                                        pelangaran</b> yang bertentangan dengan hukum yang berlaku.
                                </li>
                            </ol>
                        </Col>
                    </details>

                    <details>
                        <summary>
                            <Col xs={{size: 12}} className="title-term-service">
                                <FontAwesomeIcon icon={faHandHoldingHeart} style={{marginRight: '5px'}}/>
                                <span><b> Donatur</b></span>
                            </Col>
                        </summary>
                        <br/>
                        <Col xs={{size: 12}} className="content-term-service">
                            <ol className="ol-number">
                                <li>
                                    <b><span style={{color: '#9ecc49'}}>Wajib meneliti dan mencermati</span></b> segala
                                    informasi mengenai kampanye
                                    penggalangan dana yang dimuat di dalam Situs sebelum memberikan dukungan.
                                </li>
                                <br/>
                                <li>
                                    <b><span style={{color: '#9ecc49'}}>Tidak memberikan informasi</span></b> tambahan
                                    yang palsu dan/atau menyesatkan
                                    atas segala halaman, tautan, dan berbagai bentuk media lainnya di Zakatpedia.
                                </li>
                                <br/>
                                <li>
                                    <b><span style={{color: '#9ecc49'}}>Bertanggung jawab</span></b> atas transaksi
                                    Zakat, Infak, Sedekah dan bantuan
                                    program yang telah disalurkan.
                                </li>
                                <br/>
                                <li>
                                    <b><span style={{color: '#9ecc49'}}>Bersepakat</span></b> untuk tidak melakukan
                                    penggugatan kepada pengelola Situs.
                                </li>
                                <br/>
                                <li>
                                    <b><span
                                        style={{color: '#9ecc49'}}>Saat berdonasi secara atas nama Hamba Allah</span></b> artinya
                                    nama & alamat email
                                    donatur tidak akan terlihat di halaman campaign.
                                </li>
                                <br/>
                                <li>
                                    <b><span
                                        style={{color: '#9ecc49'}}>Wajib tidak melakukan pencucian uang</span></b> dan /
                                    atau menggunakan uang yang berasal
                                    dari sumber yang tidak Halal dan tidak sah secara hukum dalam mentransaksikan
                                    uangnya untuk Zakat, Infak, Sedekah dan bantuan program di dalam Situs.
                                </li>
                                <br/>
                                <li>
                                    <b><span
                                        style={{color: '#9ecc49'}}>Diwajibkan bertransaksi menggunakan kode Unik.</span></b>
                                </li>
                                <br/>
                                <li>
                                    <b><span style={{color: '#9ecc49'}}>Kode unik yang ada pada donasi</span></b> akan
                                    dikumpulkan pada penghimpunan Program
                                    Sedekah Kode Unik.
                                </li>
                            </ol>
                        </Col>
                    </details>
                </Row>
                <Row style={{marginTop: '50px'}}/>
            </Container>
        </div>
    )
}

export default TermService;
