import React from 'react';
import {Col, Container, Media, Row} from 'reactstrap';
import Calculator from '../components/Calculator'; 

function CalculatorZakat() {
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col className="title">Kalkulator Zakat</Col>
                </Row>
                <Row>
                    <Media src={require('../../assets/images/lines.png')} className="lines-title"/>
                </Row>
                <Row className="pd-ls">
                    <Calculator/>  
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default CalculatorZakat;
