import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import Axios from '../../../utils/axios';
import { MDBBtn } from "mdbreact";
import Loading from '../Loading';

class ZakatFitrah extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zakat: 0,
            amount_paid: 0, //Jumlah yang dibayarkan
            slug:props.slug,
            type_nisab: 'beras',
            services: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: ''

            }, messages: {
                show: false,
                success: true,
                message: 'Default message'
            }, 
            beras: []
        };
        this.handleAmountPaidChange = this.handleAmountPaidChange.bind(this);
    }

    handleAmountPaidChange(evt) {
        this.setState({amount_paid: Number(evt.target.value)});
    }
    componentDidMount = async () => {
        this.setState({isLoading: true});

        const data = await Axios.get(`/services/${this.props.slug}`);

        const dataBeras = await Axios.get(`/setting/calculator/type/${this.state.type_nisab}`);

        this.setState({services: data.data.data, beras: dataBeras.data.data, isLoading: false});
    };
    render() {

        if ((!this.state.beras[0] && !this.state.beras[1]) || (typeof this.state.beras[0] && this.state.beras[1])  === 'undefined') {
            return <Loading />
        } 

        const harga_beras = this.state.beras[0].value;  
        const nisab_zakat_fitrah = 3.5;

        const total_harga = harga_beras * nisab_zakat_fitrah;
        const zakat = this.state.amount_paid * (total_harga) ;
        const {services} = this.state;
        let validationZakat;

        if (zakat) {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT FITRAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.{zakat.toLocaleString(navigator.language, {minimumFractionDigits: 0})} ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to={{ pathname: `/carts/${services.id}`, state: { amount: `${zakat}`} }}>
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                ZAKAT
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        } else {
            validationZakat =
                <Fragment>
                    <FormGroup>
                        <Alert color="info">
                            <span>JUMLAH ZAKAT FITRAH YANG WAJIB DIBAYARKAN ADALAH:</span>
                            <br/>
                            <span className="text-amount-zakat">Rp.0 ,-</span>
                        </Alert>
                    </FormGroup>
                    <FormGroup>
                        <Link to="/programs">
                            <MDBBtn className="btn-infaq btn-rounded" block>
                                INFAQ
                            </MDBBtn>
                        </Link>
                    </FormGroup>
                </Fragment>;
        }
        return (
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Alert color="warning">

                                <span>Nishab {services.title} : sebesar 1 sha atau  </span> 
                                <span>2,176 kg beras atau dibulatkan menjadi 2,5 kg atau &nbsp;
                                 {nisab_zakat_fitrah} liter beras. Apabila di  </span>
                                <span>konversikan menjadi rupiah dengan harga </span>
                                <span>beras (Rp. {harga_beras.toLocaleString(navigator.language, {minimumFractionDigits: 0})}) per liter, adalah sebesar </span>
                                <span>Rp. {total_harga.toLocaleString(navigator.language, {minimumFractionDigits: 0})})</span>
                            </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Label for="pendapatan_utama">Jumlah Saudara yang dibayarkan zakatnya</Label>
                            <Input type="number" onChange={this.handleAmountPaidChange} name="pendapatan_utama"
                                   id="pendapatan_utama" placeholder="0"/>
                        </FormGroup>
                        {validationZakat}
                    </Form>
                </Col>
            </Row>
        )

    }
}

export default ZakatFitrah;
