import React, {Component, Fragment} from 'react';

import axios from '../../utils/axios';
import {Div} from 'glamorous';
import {Link, withRouter} from 'react-router-dom';

import {Badge, Col, Container, Media, Progress, Row} from 'reactstrap';
import TabDetail from './tabs/TabDetail';
import TabUpdate from './tabs/TabUpdate';
import TabDonors from './tabs/TabDonors';
import Tabs from './tabs/components/Tabs';
import Loading from '../components/Loading';
import { MDBBtn } from "mdbreact"; 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Detail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            program: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                raised_format: 0,
                goal_format: 0,
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: 0,
                slug: '',
                title: '',
                updated_at: '',
                precentage: 0,
                day_left: ''

            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            },

            isLoggedIn: false
        }

    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const slug = this.props.match.params.slug;

        const data = await axios.get(`/programs/${slug}`);

        this.setState({program: data.data.data, isLoading: false});
    };

    render() {
        const {program} = this.state;

        if (!program || typeof program === 'undefined') {
            return <Loading/>;
        }  
        
        return (
            <Fragment>
                <Container>
                    <Row> 
                        <LazyLoadImage className="image-program-detail" effect="blur" src={program.images_feature} />  
                    </Row>

                    <Row>
                        <Col className='title'>
                            {program.title}
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} className='lines-title'/>
                    </Row>

                    <Row>
                        <Col xs='11' className='card-donation'>
                            <Row className='pt-2'>
                                <Col xs='9'>
                                    <span style={{fontSize: '14px'}}>
                                        Rp. {program.goal_format}
                                    </span>
                                </Col>
                                <Col col={{size: 2, offset: 2}}>
                                    <Row>
                                        <Col>
                                            <span style={{fontSize: '14px'}} className='float-right'>
                                                <Badge color='secondary' className='mt-1'>
                                                {program.precentage} %
                                                </Badge>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Progress color='success' value={this.state.program.precentage}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col col='6'>
                                    <span style={{fontSize: '14px', color: '#9ecc49'}}>
                                        Rp. {program.raised_format}
                                    </span><br/>
                                    <span style={{fontSize: '14px'}}>
                                        Terkumpul
                                    </span>
                                </Col>
                                <Col col={{size: 4, offset: 2}}>
                                    <span style={{fontSize: '14px'}} className='float-right'>
                                        {program.day_left}
                                    </span><br/>
                                    <span style={{fontSize: '14px'}} className='float-right'>
                                        Hari lagi
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row> 
                    <Row className='tabs-program'>
                        <Col>
                            <Tabs activeTab={{id: 'tab1'}}>
                                <Tabs.Tab col='4' id='tab1' title='Detail'>
                                    <Div className='pt-3'>
                                        <TabDetail programs={this.state.program}/>
                                    </Div>
                                </Tabs.Tab>
                                <Tabs.Tab col='4' id='tab2' title='Update'>
                                    <Div className='pt-3'>
                                        <TabUpdate programs={this.state.program}/>
                                    </Div>
                                </Tabs.Tab>
                                <Tabs.Tab col='4' id='tab3' title='Donatur'>
                                    <Div className='pt-3'>
                                        <TabDonors programs={this.state.program}/>
                                    </Div>
                                </Tabs.Tab>
                            </Tabs>
                        </Col>
                    </Row>  
                    <Row>
                    <Link to={{ pathname: `/carts/${program.id}` }} >
                        <MDBBtn className='button-fixed btn-color-primary btn-flat'
                            style={{ color: '#fff'}}
                            disabled={!this.state.program.id} block>
                                Donasi Sekarang
                        </MDBBtn>
                    </Link>
                {/* {isLoggedIn() ? 
                    <Link to={{ pathname: `/carts/auth/${program.id}` }} >
                            <MDBBtn className='button-fixed btn-color-primary btn-flat'
                                style={{ color: '#fff'}}
                                disabled={!this.state.program.id} block>
                                Donasi Sekarang
                            </MDBBtn>
                        </Link>
                :  
                    <Link to={{ pathname: `/carts/${program.id}` }} >
                            <MDBBtn className='button-fixed btn-color-primary btn-flat'
                                style={{ color: '#fff'}}
                                disabled={!this.state.program.id} block>
                                Donasi Sekarang
                            </MDBBtn>
                        </Link>}
                         */}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default withRouter(Detail);
