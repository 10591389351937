import React, {Component} from 'react';
import {Button, Col, Container, Media, Row} from 'reactstrap';
import Axios from '../../utils/axios';
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router-dom';

class Detail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            programs: {
                content: '',
                created_at: '',
                deleted_at: '',
                description: '',
                disable_donation: '',
                donors_count: '',
                end_date: '',
                funding_type: [],
                goal: '',
                id: '',
                images_feature: '',
                images_icon: '',
                is_publish: true,
                is_service: true,
                partner: [],
                program_category: [],
                raised: '',
                slug: '',
                title: '',
                updated_at: '' 
            },
            messages: {
                show: false,
                success: true,
                message: 'Default message'
            }
        }

    }

    componentDidMount = async () => {
        this.setState({isLoading: true});

        const id = this.props.match.params.id;

        const data = await Axios.get(`/services/${id}`);

        this.setState({programs: data.data.data, isLoading: false});
    };

    render() {
        const {programs} = this.state;

        const content = programs.content; 

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Media className='image-program-detail' src={programs.images_feature} />
                    </Row>
                    <Row>
                        <Col className='title'>
                            <span> {programs.title}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Media src={require('../../assets/images/lines.png')} className='lines-title'/>
                    </Row>
                    <Row>
                        <Col style={{color: '#555555', fontSize: '14px'}}>
                            {programs.description}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col style={{color: '#555555', fontSize: '14px'}}>
                            {ReactHtmlParser(content)}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{marginBottom: '70px'}}>
                        </Col>
                    </Row>
                    <Row>
                        <Link to={{ pathname: `/carts/${programs.id}` }}>
                            <Button className='button-fixed btn-color-primary btn-flat' block>
                                Bayar Zakat Sekarang
                            </Button>
                        </Link>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

}

export default Detail;
